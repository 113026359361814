

import { mapActions } from 'vuex'

import { auyFun } from '@/api'
export default {
  name: 'facebook',
  data () {
    return {
      fbSignInParams: {
        scope: 'email,user_likes',
        return_scopes: true
      }
    }
  },
  methods: {
    ...mapActions('loginInfo', ['SETLOGIN']),
    open (a, b, c) {
      let that = this;
      that.$prompt('Connect with your email account', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            that.fbBindEmail(instance.inputValue, a, b, c, done)
            // new Promise((resolve, reject) => {             // 点击确定，进行的异步操作
            //   audit({ id: row.id, status: row.status, cause: instance.inputValue }).then(res => {
            //     if (res.code === 'R0000') {
            //       that.$message({
            //         type: 'success',
            //         message: '驳回成功'
            //       });
            //     }
            //   })
            // }).then((error) => {
            //   console.log(error);
            // })
          } else {
            done()
            // that.$message({
            //   type: 'info',
            //   message: '取消驳回'
            // });
          }
        },
        // inputValidator: value => {
        //   if (value === "") {
        //     return true;
        //   }
        //   return !(value.length > 10);
        // },
        // inputErrorMessage: 'This email is already used, please tryanother one.'
      })
      // .then(({ value }) => {
      //   this.fbBindEmail(value, a, b, c)
      // }).catch(() => {

      // });
    },
    //获取uuid
    async getUUid (k, j) {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.GETUSERUUID()
      if (data.code == 200) {
        localStorage.setItem('newuuid', data.uuid)
      }
    },
    async fbBindEmail (value, a, b, c, done) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      // let code = response.credential
      // JSON.stringify(options.params)
      // await api.googleLogin(code);

      let par = {
        email: value,
        facebook_id: a,
        uname: b,
        headimgurl: c
      }
      // console.log(code)
      var self = this
      self.$axios({
        url: "/pc/fbBindEmail",
        method: 'post',
        data: par,
      }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          done()
          self.getUUid()
          message.success(res.msg)
          self.SETLOGIN(res.data.user)
          localStorage.setItem('token', res.data.token)
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
          Cookies.set('myCookie', res.data.token, { expires: 14 });
          self.$router.go(0)
          // if (
          //   decodeURI(self.$route.query.redirect) &&
          //   self.$route.query.redirect != undefined
          // ) {
          //   // self.$router.go(-1)
          //   let str = self.$route.query.redirect
          //   if (str.indexOf('/categories') !== -1 || str.indexOf("/products") !== -1) {
          //     console.log("字符串中包含 'categories' 或者 'products' 字段");
          //     window.location = "https://www.meccle.com" + self.$route.query.redirect
          //   } else {
          //     console.log("字符串中不包含 'categories' 或者 'products' 字段");
          //     self.$router.replace({
          //       path: decodeURIComponent(self.$route.query.redirect)
          //     })
          //   }
          // } else {
          //   self.$router.replace('/')
          // }
        } else {
          message.info(res.msg)
        }
      })
    },
    mataStatistics () {
      var self = this
      let orderInfo = {
        params_json: JSON.stringify(self.form)
      }
      self.$axios({
        method: "post",
        url: link,
        data: qs.stringify(orderInfo),
      }).then((res) => {
        console.log(res.data);
      })
    },
    onSignInSuccess (response) {
      // 登录成功回调
      // FB.api('/me', dude => {
      //   console.log(`Good to see you, ${dude.name}.`)
      // })
      // console.log(response.authResponse.accessToken) //返回第三方的登录信息 tolen等
      this.handleCredentialResponse(response.authResponse.accessToken)
      // this.handleCredentialResponse(response)
    },
    async handleCredentialResponse (data) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      // let code = response.credential
      // JSON.stringify(options.params)
      // await api.googleLogin(code);

      let par = {
        access_token: data
      }
      // console.log(code)
      var self = this
      self.$axios({
        url: "/pc/facebookLogin",
        method: 'post',
        data: par,
      }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          message.success(res.msg)
          self.SETLOGIN(res.data.user)
          localStorage.setItem('token', res.data.token)
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
          Cookies.set('myCookie', res.data.token, { expires: 14 });
          Cookies.set('userInfo', JSON.stringify(res.data.user), { expires: 14 });
          self.$router.go(0)

        } else {
          self.open(res.data.user.facebook_id, res.data.user.uname, res.data.user.headimgurl)
        }
      })

    },
    onSignInError (error) {
      //登录失败回调
    }
  }
}
