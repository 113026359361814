
// import { message } from '@/utils/resetMessage'
// import { auyFun } from '@/api'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'restCart',
  props: {
    simulated: {
      type: Object
    },
    visible2: {
      type: Boolean,
      default: false
    },
    fname: String,
    ftype: Number,
    k: Number,
    k1: Number,
    type: Number
  },
  data () {
    return {
      inputDajian: "",
      product_number: 0,
      userInfo: {},
      is_reminder: false,
      is_sure: true,
      visible2Pro: this.visible2,
      cartData: {},
      num: 1,
      fag: 990,
      bigPic: "",
      spec_name: [],
      selectArr: [], //存放被选中的值
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      price: '' //选中规格的价钱
    }
  },
  watch: {
    simulated: {
      //深度监听，可监听到对象、数组的变化
      handler (newV, oldV) {
        if (newV) {
          var _this = this
          if (newV.gallery) {
            _this.bigPic = newV.gallery[0].img_url
          }
          // do something, 可使用this
          _this.shopItemInfo = {}
          _this.subIndex = []
          _this.selectArr = []
          let index
          for (var i in newV.difference) {
            _this.shopItemInfo[newV.difference[i].goods_attr] = newV.difference[i] //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
          }
          if (newV.format) {
            newV.format.forEach((item, i) => {
              item.attr_value.forEach((j, k) => {
                j.isShow = true
                if (k == 0) {
                  // 1 代表需要默认选中的规格
                  // newV.specifications[i].show_name = j.spec_name // 默认选中name
                  // _this.subIndex.push(k) // 添加默认选中规格索引
                  // _this.selectArr.push(j.goods_attr_id) // 保存默认选中规格goods_attr_id
                }
                if (newV.format.length == 1) {
                  let list = newV.format[0].attr_value
                  list.some((item, indexfr) => {
                    let k = _this.simulated.product_list.find(i => i.goods_attr == item.goods_attr_id)
                    if (k.product_number != 0) {
                      index = indexfr
                      return true
                    }
                  })
                }
                if (index) {
                  if (k == index) {
                    newV.specifications[i].show_name = j.spec_name
                    _this.subIndex.push(k)
                    _this.selectArr.push(j.goods_attr_id)
                  }
                } else {
                  if (k == 0) {
                    newV.specifications[i].show_name = j.spec_name // 默认选中name
                    this.subIndex.push(k) // 添加默认选中规格索引
                    this.selectArr.push(j.goods_attr_id) // 保存默认选中规格goods_attr_id
                  }
                }
              })
            })
          }
          // this.checkItemLuk()
          _this.checkItem()
        }

      },
      // deep: true
      immediate: true
    },
    visible2: {
      handler (newV, oldV) {
        this.num = 1
        this.visible2Pro = newV
      },
      immediate: true
    }
  },
  mounted () {
    if (process.client) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {}
    }
    // fbq('track', 'AddToCart');

    // console.log(this.simulated);
  },
  created () {

  },
  methods: {
    ...mapActions('loginInfo', ['SETLOGIN', 'SETACTIVE']),
    async subcansel () {
      let self = this
      let objInfo = {
        goods_ids: self.simulated.goods_id,
        type: 2,
        product_id: self.cartData.product_id ? self.cartData.product_id : 0,
      }
      let { data } = await self.$api.auyFun.SUBSCRCANSEL(objInfo)

      if (data.data.is_reminder == 0) {
        self.is_reminder = false
      } else {
        self.is_reminder = true
      }

    },
    async dajianOpen (j, k) {

      var self = this
      var orderInfo = {
        goods_id: self.simulated.goods_id,
        user_email: j,
        product_id: self.cartData.product_id ? self.cartData.product_id : 0,
        type: k
      }
      let { data, status } = await self.$api.auyFun.SUBSCRADD(orderInfo)
      if (status == 200) {
        if (data.data.is_reminder == 0) {
          self.is_reminder = false
        } else {
          self.is_reminder = true
        }
        self.$message.success(data.msg)
        // self.detailInfo.is_collect = 1
      } else {
        self.$message.info(data.msg)
      }
    },
    //大件提交
    goodsDajian (k) {
      if (localStorage.getItem('userInfo')) {
        var _this = this
        this.$prompt(`<div class="custom-div">${_this.simulated.overweight_pop_txt[0]}</div><div class="custom-div">${_this.simulated.overweight_pop_txt[1]}</div>`, 'Confirm your email', {
          confirmButtonText: 'Confirm',
          dangerouslyUseHTMLString: true,
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: 'The email format was entered incorrectly.',
          inputValue: _this.inputDajian,
          showCancelButton: false,
          lockScroll: false,
          confirmButtonClass: "tiancai"
        }).then(({ value }) => {
          let emi
          if (value) {
            emi = value
          } else {
            emi = _this.inputDajian
          }
          _this.dajianOpen(emi, k)
        }).catch(() => {

        });
      } else {
        this.SETACTIVE(true)
      }
    },
    //到货提醒
    async arrica (g) {
      var self = this
      if (self.is_reminder) {
        self.subcansel()
      } else {
        self.goodsDajian(2)
      }

      // let orderInfo = {
      //   goods_id: self.simulated.goods_id,
      //   product_id: self.cartData.product_id ? self.cartData.product_id : 0,
      // }
      // let { data, code, msg } = await auyFun.ARRICALREM(orderInfo)
      // if (code == 200) {
      //   message.success(msg)
      // }
    },
    close () {
      this.cartData = {}
      this.$emit('closeCat')
    },
    toDetail () {
      this.$router.push({
        path: '/content/detail',
        query: { goods_id: this.simulated.goods_id }
      })
    },
    mataStatistics (datobj) {
      if (localStorage.getItem('userInfo')) {
        var self = this
        let em = self.$sha256(self.userInfo.email)
        let ph = self.$sha256(self.userInfo.mobile_phone)
        let userAgent = navigator.userAgent;
        let clientIpAddress = self.userInfo.ip;
        let timestamp = Math.floor(new Date().getTime() / 1000)
        self.$axios({
          method: "post",
          url: "https://graph.facebook.com/v17.0/832685061480710/events?access_token=EAAYolWRudJgBO69UKBuKqn9GqCsXpP3ccQYbn2l4IDiuGMTc7ZB4qZBxP68nZAAngeRS1dkfSpOuYVt3H3OYXVb2xI6apQF6ZCmZBNxdYZAQ7tPHcdoz0lar0uZAyZALqXA3geb5xKFYMMhunrMg1R421v8CGkKbLLNVrBGM1mZCNtPnPtZCVB0J45TXrddQu2chFPXAZDZD",
          data: {
            "data": [
              {
                "event_name": "AddToCart",
                "event_time": timestamp,
                "action_source": "website",
                "event_id": datobj.cart_id,
                "user_data": {
                  "em": em,
                  "ph": ph,
                  // "client_ip_address": "254.254.254.254", "client_user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:63.0) Gecko/20100101 Firefox/63.0"
                  "client_ip_address": clientIpAddress,
                  "client_user_agent": userAgent
                },
                "custom_data": {
                  "content_ids": datobj.goods_id,
                  "content_num": datobj.goods_num,
                  "content_product_id": datobj.product_id,
                  "content_suppliers_id": datobj.suppliers_id
                }
              },
            ],
          },
        })
      }
    },
    // toCart () {
    //   // updatePrice(data) {
    //   this.detailInfo.market_price = data.market_price
    //   this.detailInfo.shop_price = data.shop_price
    //   this.product_id = data.product_id
    //   if (data.attr_img) {
    //     this.bigPic = data.attr_img
    //   }
    //   let orderInfo = {
    //     country_id: this.countryid,
    //     goods_id: this.goods_id,
    //     product_id: this.product_id
    //   }
    //   this.getShipPee(orderInfo)
    //   // },
    // },
    async mailchimpadd (k, o, l, p, cart_id) {
      let _this = this
      let objInfo = {
        goods_id: k,
        goods_num: o,
        product_id: l,
        suppliers_id: p,
        cart_id: cart_id
      }
      let { data, code, msg } = await _this.$api.auyFun.MAILCHIMPADD(objInfo)

    },
    async addCart () {
      let _this = this
      let objInfo = {
        goods_id: _this.simulated.goods_id,
        goods_num: _this.num,
        product_id: _this.cartData.product_id ? _this.cartData.product_id : 0,
        suppliers_id: _this.simulated.suppliers_id,
        is_checked: 1
      }
      let { data, status } = await _this.$api.auyFun.GET_ADDCART(objInfo)
      _this.cartData.product_id = 0
      if (status != 200) {
        _this.$message.warning(data.msg)
      } else {

        // fbq('track', 'AddToCart', {
        //   content_ids: [data.data.goods_id],
        //   contents: [
        //     { 'id': data.data.goods_id, 'quantity': data.data.this_goods_num },

        //   ],
        //   content_type: 'product',
        //   value: this.price,
        //   currency: 'USD'
        // })
        // ttq.track('AddToCart')



        // this.mailchimpadd(this.simulated.goods_id, this.num, objInfo.product_id, this.simulated.suppliers_id, data.cart_id)
        let datobj = {
          goods_id: _this.simulated.goods_id,
          goods_num: _this.num,
          product_id: objInfo.product_id,
          suppliers_id: _this.simulated.suppliers_id,
          cart_id: data.data.cart_id
        }
        _this.$message.success(data.msg)

        let dat = {
          goods_id: data.data.goods_id,
          goods_num: data.data.this_goods_num,
          fname: _this.fname,
          ftype: _this.ftype
        }
        _this.$Bus.$emit('ookkk', data.data.cart_num)
        _this.$emit('closeCat', dat)
        _this.mataStatistics(datobj)

      }
    },
    handleChange (value) {
      this.num = value
    },
    mouseOver (j, k) {
      this.bigPic = j.img_url
      this.fag = k
      // this.$set(this, "bigPic", j.img_url);
    },

    mouseleave () { },

    specificationBtn: function (item, n, event, index, attr_img, spec_name) {
      var self = this
      if (self.selectArr[n] != item) {
        self.selectArr[n] = item
        self.subIndex[n] = index
        self.simulated.specifications.forEach((j, k) => {
          if (n == k) {
            j.show_name = spec_name
          }
        })
      } else {
        self.simulated.specifications.forEach((j, k) => {
          if (n == k) {
            j.show_name = ''
          }
        })
        self.selectArr[n] = ''
        self.subIndex[n] = -1 //去掉选中的颜色
        self.price = ''
        self.$emit('updateColor')
      }
      if (attr_img) {
        self.checkItem(attr_img)
      } else {
        self.checkItem()
      }
    },
    checkItemLuk () {
      var option = this.simulated.specifications
      var self = this
      var result = [] //定义数组储存被选中的值

      if (option.length != 0) {
        for (var i in option) {
          result[i] = self.selectArr[i] ? self.selectArr[i] : ''
        }
        option[0].attr_value[0].isShow = self.isMay(result)
      }
      if (this.shopItemInfo[result]) {
        let data = {
          market_price: this.shopItemInfo[result].mark_price || '',
          shop_price: this.shopItemInfo[result].shop_price || '',
          product_id: this.shopItemInfo[result].product_id || '',
          max_num: this.shopItemInfo[result].max_num || 1,
          min_num: this.shopItemInfo[result].min_num || 1,
          attr_img: "",
          selectArr: this.selectArr
        }
        // this.$emit('updatePrice', data)
        this.cartData = data

        // this.price = this.shopItemInfo[result].mark_price || "";
      }
    },
    checkItem: function (attr_img) {
      var self = this
      var option = self.simulated.specifications
      var result = [] //定义数组储存被选中的值
      for (var i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : ''
      }
      for (var i in option) {
        var last = result[i] //把选中的值存放到字符串last去
        for (var k in option[i].attr_value) {
          result[i] = option[i].attr_value[k].goods_attr_id //赋值，存在直接覆盖，不存在往里面添加name值
          option[i].attr_value[k].isShow = self.isMay(result) //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      if (this.shopItemInfo[result]) {
        let data = {
          market_price: this.shopItemInfo[result].mark_price || '',
          shop_price: this.shopItemInfo[result].shop_price || '',
          product_id: this.shopItemInfo[result].product_id || '',
          max_num: this.shopItemInfo[result].max_num || 1,
          min_num: this.shopItemInfo[result].min_num || 1,
          attr_img: attr_img,
          selectArr: this.selectArr
        }
        this.cartData = data
        this.getsku(this.selectArr)
        // this.is_reminder = this.shopItemInfo[result].is_reminder
        // if (this.shopItemInfo[result].product_number == 0) {
        //   this.is_sure = false
        // }
        // this.price = this.shopItemInfo[result].mark_price || "";
      } else {
        this.product_number = this.simulated.goods_number
      }
      this.inputDajian = this.simulated.user_email
      self.$forceUpdate() //重绘
    },
    isMay: function (result) {
      for (var i in result) {
        if (result[i] == '' || result[i] == undefined) {
          return true //如果数组里有为空的值，那直接返回true
        }
      }
      return this.shopItemInfo[result].product_number == 0 ? false : true //匹配选中的数据的库存，若不为空返回true反之返回false


    },
    async getsku (k) {
      let objInfo = {
        goods_attr_id: k,
        goods_id: this.simulated.goods_id,
      }
      var _this = this
      let { data, status } = await _this.$api.auyFun.GETSKU(objInfo)
      if (status == 200) {
        var kk = k.join(",")
        data.data.forEach((j, k) => {
          _this.product_number = data.data[0].product_number
          if (kk == j.goods_attr) {
            //判断是否有库存
            if (j.product_number == 0) {

              _this.is_sure = false
            } else {
              _this.is_sure = true
            }
            if (j.is_reminder == 0) {
              _this.is_reminder = false
            } else {
              _this.is_reminder = true
            }

          }
        })
      }
    },
  }
}
