

// import VueTwitterLogin from 'vue-twitter-login';
import { mapActions } from 'vuex'
// import sha1 from "sha1";
export default {
  name: 'twitter',
  components: {
  },
  data () {
    return {
      yourTwitterApiKey: 'IZUWNcWVDIbuHQ0YqJqb1eyCK',
      yourRedirectUri: 'http://localhost:8093/',
      popupWindow: null
    };
  },
  computed: {},
  watch: {},
  created () {


  },
  mounted () {

  },

  methods: {
    ...mapActions('loginInfo', ['SETLOGIN']),
    login_tw () {
      // console.log(code)
      var self = this
      self.$axios({
        url: "https://appen.meccle.com/v3/pc/twitterLogin",
        method: 'get',
        data: {},
      }).then((res) => {
        if (res.code == 200) {
          self.loginWithTwitter(res.data.oauth_token)
        }
      })
      // success: (res) => {
      //   debugger
      //   if (res.data.code == 200) {
      //     self.loginWithTwitter(res.data.oauth_token)
      //   }
      // }

    },
    //32随机数
    getNum () {
      var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F',
        'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
        'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
        'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      var nums = "";
      for (var i = 0; i < 32; i++) {
        var id = parseInt(Math.random() * 61);
        nums += chars[id];
      }
      console.log(nums)
      return nums;

    },

    getTimer: function () {
      /**
       * 获取时间戳  秒级
       */
      console.log(parseInt(new Date().getTime() / 1000))
      return parseInt(new Date().getTime() / 1000);
    },
    //   curl --request POST \
    // --url 'https://api.twitter.com/oauth/request_token?oauth_callback=$HTTP_ENCODED_CALLBACK_URL' \
    // --header 'Authorization: OAuth oauth_consumer_key="$oauth_consumer_key", oauth_nonce="$oauth_nonce",
    //  oauth_signature="oauth_signature", oauth_signature_method="HMAC-SHA1", oauth_timestamp="$timestamp", oauth_version="1.0"'
    // POST&https%3A%2F%2Fapi.twitter.com%2F1.1%2Fstatuses%2Fupdate.json&
    // &oauth_token=370773112-GmHxMAgYyLbNEtIKZeRNFsMKPR9EyMZeS9weJAEb
    gettest () {
      let oauth_timestamp = this.getTimer()
      let oauth_nonce = this.getNum()
      // 获取用户地址
      var self = this
      // let orderInfo = {
      //   params_json: JSON.stringify(self.form)
      // }
      // "https://api.twitter.com/oauth/request_token?oauth_callback="
      let link = "https://api.twitter.com/oauth/request_token"
      let link_encodeURIComponent = encodeURIComponent(link)
      console.log(link_encodeURIComponent)
      let text = link_encodeURIComponent + "&oauth_consumer_key=IZUWNcWVDIbuHQ0YqJqb1eyCK&oauth_nonce=" + oauth_nonce + "&oauth_signature_method=HMAC-SHA1&oauth_timestamp=" + oauth_timestamp + "&oauth_version=1.0"
      //sha加密
      let sha = self.$sha256(text)
      console.log(sha)
      self.$axios({
        method: "post",
        url: link,
        // data: qs.stringify(orderInfo)
        data: {
          oauth_callback: "http://localhost:8093/"
        },
        headers: {
          oauth_consumer_key: "IZUWNcWVDIbuHQ0YqJqb1eyCK",
          oauth_nonce: oauth_nonce,
          oauth_signature_method: "HMAC-SHA1",
          oauth_timestamp: oauth_timestamp,
          oauth_version: "1.0",
          oauth_signature: sha,
        }
      }).then((res) => {

      })
    },
    //获取uuid
    async getUUid (k, j) {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.GETUSERUUID()
      if (data.code == 200) {
        localStorage.setItem('newuuid', data.uuid)
      }
    },
    openTwitterLoginPopup (url) {
      var self = this
      const width = 600;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      // 在弹出窗口中打开推特授权页面
      var popup = window.open(url,
        'Twitter Login',
        `width=${width}, height=${height}, left=${left}, top=${top}`);

      // 监听弹出窗口的消息事件
      window.addEventListener("message", (res) => {
        // 处理从弹出窗口传递回来的消息
        // let bb = JSON.stringify(event.data.token)
        if (res.data.token) {
          popup.close();
          self.getUUid()
          self.SETLOGIN(res.data.user)
          localStorage.setItem('token', res.data.token)
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
          Cookies.set('myCookie', res.data.token, { expires: 14 });
          Cookies.set('userInfo', JSON.stringify(res.data.user), { expires: 14 });
          self.$router.go(0)
          // if (
          //   decodeURI(self.$route.query.redirect) &&
          //   self.$route.query.redirect != undefined
          // ) {
          //   self.$router.replace({
          //     path: decodeURIComponent(self.$route.query.redirect)
          //   })
          // } else {
          //   self.$router.replace('/')
          // }
        }
        if (res.data.cancelled == 1) {
          popup.close();
          self.$router.go(0)
        }
      });
    },
    loginWithTwitter (k) {
      // 触发登录流程
      // &oauth_callback=${encodeURIComponent(redirectUri)}
      const redirectUri = 'http://localhost:8093/';
      const consumerKey = k;
      // https://api.twitter.com/oauth/authenticate?oauth_token=Z6eEdO8MOmk394WozF5oKyuAv855l4Mlqo7hxxxxxx
      // this.gettest()
      // 构建OAuth请求URL
      const oauthUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${consumerKey}&oauth_callback=${encodeURIComponent(redirectUri)}`;
      // let url = "https://appen.meccle.com/v3/pc/twitterLogin"
      // this.openTwitterLoginPopup(url)
      this.openTwitterLoginPopup(oauthUrl)
      // 重定向用户到Twitter的OAuth请求URL
      // window.location.href = oauthUrl;
    },
  }
}
