// 在Nuxt 2项目中，这里假设是在plugins目录下创建的一个插件文件，比如叫customJumpPlugin.js
export default ({ app,store }, inject) => {
  // 创建全局跳转方法
  const customJump = (to) => {
      const router = app.router;

      if (!router) {
          throw new Error('Router instance not available.');
      }

      let targetPath = typeof to === 'string'? to : to.path;
      let queryParams = typeof to === 'object' && to.query? to.query : {};
      // 判断路径是否包含 "content"
    //   console.log(localStorage.getItem('token'))
      if (targetPath.includes('content')) {
        let url = targetPath;
      if(localStorage.getItem('token')){
        if (Object.keys(queryParams).length > 0) {
            const queryString = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&');
            url += `?${queryString}`;
        }
        window.location.href = process.env.link_URL+url;
      }else{
        const pattern = /\/grandopening|\/Recommendation|\/inivt|\/Withdraw|\/cash|\/FactoryMember|\/haoMeccle|\/pointMeccle|\/shareMeccle|\/MyRecommend|\/MyInvitation|\/discount|\/storeCollect|\/createOrder|\/payment|\/address|\/order/;
        if(url.match(pattern)!== null){
            console.log(url)
            store.dispatch('loginInfo/SETACTIVE', true);
        }else{
            if (Object.keys(queryParams).length > 0) {
                const queryString = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&');
                url += `?${queryString}`;
            }
            window.location.href = process.env.link_URL+url;
        }
      }
      
          // 如果包含 "content"，构建完整的带有查询参数的URL
       
     
      } else {
          // 如果不包含 "content"，构建目标路由对象并使用 $router.push进行跳转
          const targetRoute = {
              path: targetPath,
              query: queryParams
          };
          return router.push(targetRoute);
      }
  };

  // 将全局跳转方法挂载到app实例上，方便在组件中使用
  inject('customJump', customJump);
  // app.$customJump = customJump;
};