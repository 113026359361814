
import login from '@/components/login/loginSign'
export default {
  data () {
    return {}
  },
  components: { login },
  computed: {
    sactive () {
      return this.$store.state.loginInfo.sactive
    },
  },
  // 设置head信息
  head () {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: ''
        }
      ]
    }
  },
   mounted () { this.$store.dispatch('loginInfo/SETACTIVE', false); },
}
