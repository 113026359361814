export const NuxtLogo = () => import('../..\\components\\NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../..\\components\\Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const AlertboxAlertone = () => import('../..\\components\\alertbox\\alertone.vue' /* webpackChunkName: "components/alertbox-alertone" */).then(c => wrapFunctional(c.default || c))
export const AlertboxAlerttwo = () => import('../..\\components\\alertbox\\alerttwo.vue' /* webpackChunkName: "components/alertbox-alerttwo" */).then(c => wrapFunctional(c.default || c))
export const AlertPopLuckDzp = () => import('../..\\components\\alertPop\\luckDzp.vue' /* webpackChunkName: "components/alert-pop-luck-dzp" */).then(c => wrapFunctional(c.default || c))
export const AlertPopLuckDzpLogin = () => import('../..\\components\\alertPop\\luckDzpLogin.vue' /* webpackChunkName: "components/alert-pop-luck-dzp-login" */).then(c => wrapFunctional(c.default || c))
export const AlertPopTimeing = () => import('../..\\components\\alertPop\\timeing.vue' /* webpackChunkName: "components/alert-pop-timeing" */).then(c => wrapFunctional(c.default || c))
export const AnimationAnimaIndex = () => import('../..\\components\\animation\\animaIndex.vue' /* webpackChunkName: "components/animation-anima-index" */).then(c => wrapFunctional(c.default || c))
export const BackTop = () => import('../..\\components\\BackTop\\index.vue' /* webpackChunkName: "components/back-top" */).then(c => wrapFunctional(c.default || c))
export const BigimgItem = () => import('../..\\components\\bigimg\\bigimgItem.vue' /* webpackChunkName: "components/bigimg-item" */).then(c => wrapFunctional(c.default || c))
export const BigimgItemDetail = () => import('../..\\components\\bigimg\\bigimgItemDetail.vue' /* webpackChunkName: "components/bigimg-item-detail" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../..\\components\\countdown\\countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const CountdownDzp = () => import('../..\\components\\countdown\\countdownDzp.vue' /* webpackChunkName: "components/countdown-dzp" */).then(c => wrapFunctional(c.default || c))
export const Discounts = () => import('../..\\components\\discountsPopUp\\discounts.vue' /* webpackChunkName: "components/discounts" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../..\\components\\Footer\\index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../..\\components\\Loading\\index.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../..\\components\\login\\login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const LoginSign = () => import('../..\\components\\login\\loginSign.vue' /* webpackChunkName: "components/login-sign" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderCute = () => import('../..\\components\\MagicSlider\\cute.vue' /* webpackChunkName: "components/magic-slider-cute" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderHomecommon = () => import('../..\\components\\MagicSlider\\homecommon.vue' /* webpackChunkName: "components/magic-slider-homecommon" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderMasters = () => import('../..\\components\\MagicSlider\\masters.vue' /* webpackChunkName: "components/magic-slider-masters" */).then(c => wrapFunctional(c.default || c))
export const NavBarHorizontal = () => import('../..\\components\\NavBar\\horizontal.vue' /* webpackChunkName: "components/nav-bar-horizontal" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../..\\components\\NavBar\\index.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NavBarSidebarItem = () => import('../..\\components\\NavBar\\sidebarItem.vue' /* webpackChunkName: "components/nav-bar-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const PublicList = () => import('../..\\components\\publicLists\\publicList.vue' /* webpackChunkName: "components/public-list" */).then(c => wrapFunctional(c.default || c))
export const Rest = () => import('../..\\components\\restCart\\rest.vue' /* webpackChunkName: "components/rest" */).then(c => wrapFunctional(c.default || c))
export const RestCart = () => import('../..\\components\\restCart\\restCart.vue' /* webpackChunkName: "components/rest-cart" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../..\\components\\svgIcon\\index.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const VideoNuxt = () => import('../..\\components\\VideoNuxt\\index.vue' /* webpackChunkName: "components/video-nuxt" */).then(c => wrapFunctional(c.default || c))
export const LoginFacebook = () => import('../..\\components\\login\\facebook\\facebook.vue' /* webpackChunkName: "components/login-facebook" */).then(c => wrapFunctional(c.default || c))
export const LoginTwitter = () => import('../..\\components\\login\\twitter\\twitter.vue' /* webpackChunkName: "components/login-twitter" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderFiveIndex = () => import('../..\\components\\MagicSlider\\fiveItem\\fiveIndex.vue' /* webpackChunkName: "components/magic-slider-five-index" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderSwiperBanner = () => import('../..\\components\\MagicSlider\\swiper\\banner.vue' /* webpackChunkName: "components/magic-slider-swiper-banner" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderSwiperBeifen = () => import('../..\\components\\MagicSlider\\swiper\\beifen.vue' /* webpackChunkName: "components/magic-slider-swiper-beifen" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderSwiperDetailSwiper = () => import('../..\\components\\MagicSlider\\swiper\\detailSwiper.vue' /* webpackChunkName: "components/magic-slider-swiper-detail-swiper" */).then(c => wrapFunctional(c.default || c))
export const MagicSliderSwiper = () => import('../..\\components\\MagicSlider\\swiper\\index.vue' /* webpackChunkName: "components/magic-slider-swiper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
