
import { mapActions, mapState } from 'vuex'
import facebook from '@/components/login/facebook/facebook'
import twitter from '@/components/login/twitter/twitter'
import Cookies from 'js-cookie';
export default {
  name: 'login',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('enter your PIN'))
      } else if (value.length < 6) {
        callback(new Error('The password must contain at least six characters'))
      } else {
        callback()
      }
    }
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
      if (!value) {
        return callback(new Error('The email cannot be empty'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter the correct email format'))
        }
      }, 100)
    }
    return {
      iframeSrc: "",
      dialogVisible: false, // 用户协议展示框
      agreement: '',
      pc_switch: 0,
      other_login_img: [],
      ruleForm: {
        pass: '',
        email: ''
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur', required: true }],
        email: [{ validator: checkEmail, trigger: 'blur', required: true }]
      }
    }
  },
  components: { facebook, twitter },
  mounted () {
    // AppleID.auth.init({
    //   clientId: 'com.bulkbuyworld.www',
    //   scope: 'name email',
    //   redirectURI: 'https://home.meccle.com/',
    //   state: 'padding',
    //   usePopup: true
    // });
    this.othersign()
    //监听浏览器返回
    // if (window.history && window.history.pushState) {

    // history.pushState(null, null, document.URL)

    //   window.addEventListener('popstate', this.goPageBefore)
    //   // }
    // },
    // beforeDestroy () {
    //   window.removeEventListener('popstate', this.goPageBefore)
    // },

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goPageBefore, false)
    }

  },
  destroyed () {
    window.removeEventListener('popstate', this.goPageBefore, false)
  },
  methods: {
    ...mapActions('loginInfo', ['SETLOGIN']),
    //获取uuid
    async getUUid (k, j) {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.GETUSERUUID()
      if (data.code == 200) {
        localStorage.setItem('newuuid', data.data.uuid)
      }
    },
    async client () {
      let client
      var _this = this
      if (typeof (google) == "object") {
        client = await google.accounts.oauth2.initCodeClient({
          // client_id: '461018696142-pf6v6saoait4shvgr9s6ppgd3ncouqi7.apps.googleusercontent.com',
          client_id: '461876387606-prm04fca7gv2rek6k00hq83n4kub977t.apps.googleusercontent.com',
          // scope: 'profile email',
          scope: 'profile email',
          ux_mode: 'popup',
          callback: (response) => {
            // console.log(response)
            let obj = {
              device: "pc"
            }
            let response_info = {
              ...response, ...obj
            }
            _this.handleCredentialResponse(response_info)

          },
        });
        client.requestCode();
      }
    },
    async handleCredentialResponse (data) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      // let code = response.credential
      // JSON.stringify(options.params)
      // await api.googleLogin(code);
      // console.log(code)
      var self = this
      self.$axios({
        url: "pc/codeGoogleLogin",
        method: 'post',
        data: { data },
        success: (res) => {
          if (res.code == 200) {
            self.getUUid()//获取uuid
            message.success(res.msg)
            self.SETLOGIN(res.data.user)
            localStorage.setItem('token', res.data.token)
            sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
            Cookies.set('myCookie', res.data.token, { expires: 14 });
            Cookies.set('userInfo', JSON.stringify(res.data.user), { expires: 14 });
            if (
              decodeURI(self.$route.query.redirect) &&
              self.$route.query.redirect != undefined
            ) {
              // self.$router.go(-1)
              let str = self.$route.query.redirect
              if (str.indexOf('/categories') !== -1 || str.indexOf("/products") !== -1) {
                console.log("字符串中包含 'categories' 或者 'products' 字段");
                window.location = "https://www.meccle.com" + self.$route.query.redirect
              } else {
                console.log("字符串中不包含 'categories' 或者 'products' 字段");
                self.$router.replace({
                  path: decodeURIComponent(self.$route.query.redirect)
                })
              }
            } else {
              self.$router.replace('/')
            }
          } else {
            message.warning(res.msg)
          }

        }
      })
    },
    async onAgreement (type) {
      this.agreement = ''
      this.dialogVisible = true
      const { data, code } = await orderPay.JOIN_AGREEMENT({
        type: type
      })
      if (code == 200) this.agreement = data
    },

    async othersign () {
      let objInfo = {}
      let { data, code, msg } = await this.$api.auyFun.OTHERLOGIN(objInfo)
      if (data.code == 200) {
        this.pc_switch = data.data.pc_switch
        this.other_login_img = data.data
      } else {
        // this.$message.warning(msg)
      }
    },
    toLink () {
      this.$router.push('/register')
      // this.$router.push('/registerAccount')

    },
    // handleCredentialResponse(response) {
    // 	// 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
    // 	let code = response.credential
    // 	// await api.googleLogin(code);
    // 	console.log(code)
    // },
    tolinks (k) {
      if (k == 'google') {
        // window.location.href = 'http://www.psj001.com/pc/authorization?platformType=3'
        this.client()
      } else if (k == 'facebook') {
        // this.iframeSrc = "https://www.psj001.com/pc/facebook"
      } else {
        message({
          message: 'Under development',
          type: 'info'
        })
        // this.$message.info('Under development')
      }
    },
    goPageBefore () {

      console.log(process.env.NODE_ENV)
      if (process.env.NODE_ENV == "production") {
        window.location = "https://www.meccle.com/"
        // if(window.location.href==pcurl+"content/"){
        //   window.location=pcurl
        // }
      } else if (process.env.NODE_ENV == "test") {
        window.location = "https://test2.bulkbuyworld.com/"
      } else {
        this.$router.push('/')
      }

    },
    onforget () {
      this.$router.push('/modification')
    },
    login () {
      var self = this
      let data = {
        email: self.ruleForm.email,
        password: self.ruleForm.pass
      }
      self.$axios({
        url: "/pc/login",
        method: 'post',
        data: { data },
        success: (res) => {
          if (res.code == 200) {
            self.getUUid()
            message.success(res.msg)
            self.SETLOGIN(res.data.user)
            localStorage.setItem('token', res.data.token)
            sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
            Cookies.set('myCookie', res.data.token, { expires: 14 });
            Cookies.set('userInfo', JSON.stringify(res.data.user), { expires: 14 });
            if (
              decodeURI(self.$route.query.redirect) &&
              self.$route.query.redirect != undefined
            ) {
              let str = self.$route.query.redirect
              if (str.indexOf('/categories') !== -1 || str.indexOf("/products") !== -1) {
                console.log("字符串中包含 'categories' 或者 'products' 字段");
                window.location = "https://www.meccle.com" + self.$route.query.redirect
              } else {
                console.log("字符串中不包含 'categories' 或者 'products' 字段");
                self.$router.replace({
                  path: decodeURIComponent(self.$route.query.redirect)
                })
              }
              // self.$router.go(-1)

            } else {
              self.$router.replace('/')
            }
          } else {
            message.warning(res.msg)
          }
        }
      })
    }
  }
}
