
import login from '@/components/login/loginSign'
export default {
  name: 'BaseLayouts',
  data () {
    return {
      loading: true,
      showLogin: false
    }
  },
  components: { login },
  computed: {
    sactive () {
      return this.$store.state.loginInfo.sactive
    },
  },
  watch: {
    '$route': {
      handler () {
        this.renderLoading()
      },
      immediate: true,
      deep: true
    },
    // 'sactive': {
    //   handler (newV, oldV) {
    //     this.showLogin = newV
    //   },
    //   deep: true
    // },
  },
  created () {
    this.renderLoading()
  },
  mounted () { this.$store.dispatch('loginInfo/SETACTIVE', false); },

  methods: {
    renderLoading () {
      this.loading = true
      const timer = setTimeout(() => {
        this.loading = false
      }, 1500)

      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
        this.loading = true
      })
    }
  },
  head () {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: ''
        }
      ]
    }
  }
}
