
import { mapActions, mapState } from 'vuex'
export default {
  props: {

  },
  data () {
    return {
      visible: false,
      Applycode: "",
      plz: false,
      city: "",
      chectBol: true,
      city_name: "",
      post_code: "",
      showfa: 1
    }
  },
  computed: {},
  created () {
    // if (localStorage.getItem('Applycity')) {
    //   this.chectBol = false
    // }
  },
  mounted () {
    if (process.client) {
      this.Applycode = localStorage.getItem('Applycode') ? localStorage.getItem('Applycode') : ""
      if (localStorage.getItem('Applycity')) {
        this.chectBol = false
      }
      if (localStorage.getItem('userInfo')) {
        this.showfa = 1
      } else {
        this.showfa = 2
      }
    }
  },
  watch: {},
  methods: {
    ...mapActions('addressindex', ['SETADDRESSCODE']),
    ...mapActions('loginInfo', ['SETACTIVE']),
    Changeto () {
      this.chectBol = true
    },
    async codelist () {
      // var self = this
      // plz //显示zipcode错误提示
      //chectBol 显示输入的zipcode是否存在
      if (this.Applycode.length == 5) {
        var objInfo = {
          code: this.Applycode,
        }
        let { data } = await this.$api.auyFun.CODELIST(objInfo)
        if (data.data.length > 0) {
          this.chectBol = false
          this.city = data.data[0].city
          this.plz = false
          localStorage.setItem('Applycode', data.data[0].code)
          localStorage.setItem('Applycity', data.data[0].city)
        } else {
          this.plz = true
        }
      } else {
        this.plz = true
      }

    },
    check () {
      if (localStorage.getItem('Applycity')) {
        this.chectBol = false
        this.Applycode = localStorage.getItem('Applycode')
      }
      this.visible = !this.visible
    },
    toaddress () {
      this.$customJump({
        path: '/content/address/2'
      })
    },
    doneUp () {
      // this.$emit('upShow', localStorage.getItem('Applycode'), localStorage.getItem('Applycity'))
      // this.check()
      var _this = this
      _this.check()
      if (localStorage.getItem('Applycode')) {
        _this.$emit('upShow', localStorage.getItem('Applycode'), localStorage.getItem('Applycity'))
        var obj = {
          Applycode: localStorage.getItem('Applycode'),
          Applycity: localStorage.getItem('Applycity'),
          address_id: _this.address_id
        }
        _this.SETADDRESSCODE(obj)
      } else {
        _this.$emit('upShow', _this.post_code, _this.city_name)
        var obj = {
          Applycode: _this.post_code,
          Applycity: _this.city_name,
          address_id: _this.address_id
        }
        _this.SETADDRESSCODE(obj)
      }

    },
    tologin () {
      this.SETACTIVE(true)
    }
  },
  components: {}
}
