import { render, staticRenderFns } from "./restCart.vue?vue&type=template&id=b22a03a2&"
import script from "./restCart.vue?vue&type=script&lang=js&"
export * from "./restCart.vue?vue&type=script&lang=js&"
import style0 from "./restCart.vue?vue&type=style&index=0&id=b22a03a2&prod&lang=scss&rel=stylesheet&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports