
import luckDzpLogin from '@/components/alertPop/luckDzpLogin.vue'
import device from '@/utils/device'
import cookieparser from 'cookieparser'; // 用于解析cookie
import fiveIndex from '@/components/MagicSlider/fiveItem/fiveIndex'
import shopswiper from '@/components/MagicSlider/swiper/index'
import homecommon from '@/components/MagicSlider/homecommon'
import publicList from '@/components/publicLists/publicList.vue'
import luckDzp from '@/components/alertPop/luckDzp.vue'
import { mapActions, mapState } from 'vuex'
// import MagicSliderCute from '@/components/MagicSlider/cute'
// import MagicSliderMasters from '@/components/MagicSlider/masters'
export default {
  components: { fiveIndex, shopswiper, homecommon, publicList, luckDzp, luckDzpLogin },
  name: 'Index',
  layout: 'base',
  async asyncData ({ app }) {
    let con_img = [], contData = {}, debingList = [] // 用 let 而不是 var
    let dataBox = {
      mark: 'index_top',
      num: 6
    };
    try {
      const { status, data } = await app.$api.auyFun.ADLISTNEW(dataBox);
      if (status === 200) {
        con_img = data.data; // 赋值
      }
    } catch (error) {
    }
    try {
      const { status, data } = await app.$api.auyFun.INDEXHOME();
      if (status === 200) {

        contData = data.data; // 赋值
        console.log(contData)
      }
    } catch (error) {
    }
    // try {
    //   const { status, data } = await app.$api.auyFun.INDEXHOME();
    //   if (status === 200) {
    //     contData = data.data; // 赋值
    //   }
    // } catch (error) {
    // }
    try {
      let params = {
        page: 1,
        pz: 50,
      }
      const { status, data } = await app.$api.auyFun.INDEXGOODSLIST(params)
      if (status === 200) {

        debingList = data.data.data; // 赋值
      }
    } catch (error) {
    }

    return { con_img, contData, debingList }; // 确保最终返回 con_img
  },

  //
  // 异步数据用法

  data () {
    return {
      showfa: 2,
      custom: false,
      userInfo: {},
      popList: {
        one: {},
        two: {},
        three: {}
      },
      indexDzptime: 0,
      centerDialogVisible: false,
      bannerHeight: 285,
      isSpider: device.isSpider,
      loading: false,
      headTitle: '',
      headKeywords: '',
      headDesc: '',
      tween: null, // 实例
      currentSlider: 0,
      showBall: false
    }
  },
  async mounted () {
    if (process.client) {
      // this.indexft()
      // this.autoexp()

      this.userInfo = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {}
      this.showfa = Object.keys(this.userInfo).length <= 0 ? 1 : 2
      if (localStorage.getItem('newuuid')) {
      } else {
        this.getUUid()
      }
      // this.getInfo()
      // this.getHistoryList()
      // this.navbar()
    }

    // GSAP 动画测试组件 TODO
    // this.animate1()
    // this.initBall()
  },
  computed: {
    // ...mapState('loginInfo', ['couponInfo', 'userInfo']),
    is_conpon () {
      return this.$store.state.loginInfo.userInfo
    },
  },
  methods: {
    //优惠卷倒计时
    updateindex (data) {
      if (data.timeNum == 0) {
        this.autoexp(data.index)
      }
    },
    //优惠卷提示
    cuonpOpen (img) {
      this.$alert('<img src = ' + img + ' />', {
        confirmButtonText: 'OK',
        dangerouslyUseHTMLString: true,//true的时候message会被作为HTML片段处理
        center: true,
        confirmButtonClass: "cuonpOpenok"
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      })
    },




    dzp_visible () {
      this.custom = false
    },
    //首页弹窗显示
    async indexft () {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.INDEXGIFTPOP()
      if (data.code != 200) {

      } else {
        self.centerDialogVisible = true
        self.popList = data.data
        // self.autoexp()
      }
    },
    async getUUid (k, j) {
      var self = this
      let { status, data } = await self.$api.auyFun.GETUSERUUID()
      if (status == 200) {
        localStorage.setItem('newuuid', data.data.uuid)
      }
    },
    //加入购物车后前端跟新数据
    update (data) {
      this.debingList.forEach((j, k) => {
        if (data.goods_id == j.goods_id) {
          j.cart_num = data.goods_num
        }
      })
    },
    updatehomeTYPE (data) {
      console.log(this.contData[data.upName].list[data.hometype].goods)
      this.contData[data.upName].list[data.hometype].goods.forEach((j, k) => {
        if (data.goods_id == j.goods_id) {
          j.cart_num = data.goods_num
        }
      })
      console.log(this.contData.oversea_goods)
    },
    updatehome (data) {
      this.contData[data.upName].list.forEach((j, k) => {
        if (data.goods_id == j.goods_id) {
          j.cart_num = data.goods_num
        }
      })
    },
    addTOcatr (j, k, o, f, type) {
      if (j == 1) {
        this.getShopDetail(k, f, type)
      } else {
        this.addCart(k, o, f, type)
      }
    },
    getShopDetail (k, f, type) {
      var self = this
      var data = {
        goods_id: k,
      }
      //   data = fly.initData(data);
      this.$axios({
        url: "/pc/goods/goodsSku",
        method: 'get',
        params: data
      })
        .then((res) => {
          if (res.code == 200) {
            self.simulated = res.data
            self.simulated.difference = res.data.product_list
            res.data.format.forEach((j, k) => {
              j.show_name = ''
            })
            self.simulated.specifications = res.data.format
            self.visible2 = true
            self.fname = f
            self.ftype = type
            // self.contData = res.data;
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    todiscount () {
      if (this.is_conpon.is_coupon == 1) {
        // this.$emit('updateStu', true)
        localStorage.setItem('centerDialogVisibletime', 1)
      } else {
        this.$customJump("/content/discount")
      }
    },
    toceshi () {
      window.location = "https://test2.bulkbuyworld.com/content/AboutUs"
      // this.$router.replace("")
    },
    toabout () {
      this.$customJump("/content/AboutUs")
    },
    //轮播
    toGolink (k) {
      debugger
      if (k) {
        this.$router.push({
          path: "categories/" + k.ad_mark
        })
      }
      // if (k) {
      //   this.$customJump('/content/' + k)
      // }

    },
    //首页菜单·
    navbar () {
      var self = this
      var data = {}
      //   data = fly.initData(data);
      this.$axios({
        url: "/pc/navbar",
        method: 'get',
        params: data
      })
        .then((res) => {
          if (res.code == 200) {
            this.navbarList = res.data
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    //历史记录
    async getHistoryList () {
      var self = this
      var objInfo = {
        // page: self.listData.pageIndex,
        page: 0,
        pageSize: '30'
      }
      let { data } = await self.$api.auyFun.GET_HISTORY(objInfo)
      // if (self.listData.pageIndex > 0) {
      //   self.historyList.data.push(data)
      // } else {
      //   self.historyList = data
      // }
      // self.SETHISTORYLIST(self.historyList)

    },
    async getInfo () {
      const params = {
        headTitle: '2_不是爬虫',
        headKeywords: '2_不是爬虫',
        headDesc: '2_不是爬虫'
      }
      const { headTitle, headKeywords, headDesc } = await this.$store.dispatch('test/getTestInfo', params)
      this.headTitle = headTitle
      this.headKeywords = headKeywords
      this.headDesc = headDesc
    },
    /** *******tween max animation**********/
    play () {
      this.tween.play()

      // setTimeout( () => {
      //   this.$gsap.killTweensOf( this.$refs['tween-max-item1'], 'opacity,y' )
      // }, 2000 )
    },
    inputSlider (val) {
      // console.log( 'inputSlider', val )
      this.tween.pause()
      this.tween.progress(val * 0.01)

      // // 干掉指定得动画,多个用都好分割
      // this.$gsap.killTweensOf( this.$refs['tween-max-item1'], 'opacity' )

      // // 暂停/完成/未开始 返回false
      // console.log( 'isTweening', this.$gsap.isTweening( this.$refs['tween-max-item1'] ) )

      // this.getTweensOf( this.$refs['tween-max-item1'] )
      // this.getProperty()
    },
    animate1 () {
      const gsap = this.$gsap


      const el = this.$refs['tween-max-item1']
      if (!gsap || !el) return

      // 透明度动画

      // const tween = gsap.fromTo(
      //   el,
      //   {
      //     opacity : 1
      //   },
      //   {
      //     opacity : 0
      //     // duration : 1,
      //     // ease : 'elastic'
      //   }
      // )

      const tween = gsap.from(el, {
        id: 'toAnimate',
        opacity: 0,
        y: 400,
        duration: 6
      })

      // const tween = gsap.set( el, { y : 400, opacity : 0 } )

      tween.pause()

      this.tween = tween
    },
    initBall () {
      this.showBall = true
      this.$nextTick(() => {
        const gsap = this.$gsap
        const el = this.$refs['ball']
        if (!gsap || !el) return
        gsap.set(el, { xPercent: -50, yPercent: -50 })

        const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
        const mouse = { x: pos.x, y: pos.y }
        const speed = 0.35

        const xSet = gsap.quickSetter(el, 'x', 'px')
        const ySet = gsap.quickSetter(el, 'y', 'px')

        window.addEventListener('mousemove', e => {
          mouse.x = e.x
          mouse.y = e.y
        })

        gsap.ticker.add(() => {
          // adjust speed for higher refresh monitors
          const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())
          pos.x += (mouse.x - pos.x) * dt
          pos.y += (mouse.y - pos.y) * dt
          xSet(pos.x)
          ySet(pos.y)
        })
      })
    },
    // 获取元素属性
    getProperty () {
      const gsap = this.$gsap
      const el = this.$refs['tween-max-item1']
      const res = gsap.getProperty(el, 'width', 'px')
    },
    toClickmorew () {
      this.clickmore = true
      this.page = (
        parseInt(this.page) + 1
      ).toString();
      // this.debing()
    },
    //
    tosharemeccle (pc_home_url) {
      this.$customJump({
        path: '/content/' + pc_home_url,
      })
    },
    getTweensOf (target) {
      var res = this.$gsap.getTweensOf(target)
    }
  },
  head () {
    return {
      title: this.headTitle,
      meta: [
        { hid: 'keywords', name: 'keywords', content: this.headKeywords },
        { hid: 'description', name: 'description', content: this.headDesc }
      ]
    }
  }
}
