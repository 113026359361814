
import restCart from '@/components/restCart/restCart.vue'
export default {
  name: '',
  props: {
    contData: {
      type: Object,
      default: {},
    },
    upName: String,
    tips: String
  },
  components: { restCart },
  data () {
    return {
      userInfo: {},
      simulated: {},
      visible2: false,
      fname: "",
      ftype: 0
    }
  },
  computed: {},
  watch: {},
  created () {

  },
  mounted () {
    this.userInfo = this.$storage.getLocalStorage('userInfo')
      ? this.$storage.getLocalStorage('userInfo')
      : {}
  },

  methods: {
    closeCat (data) {
      //更新没购物车数来那个角标
      if (data) {
        data.upName = this.upName
        this.update(data)
      }
      this.visible2 = false
    },
    update (data) {
      this.$emit('updatehome', data)
    },
    toDetail (k) {
      var routeData = this.$router.resolve({
        path: '/detail',
        query: { goods_id: k }
      })
      window.open(routeData.href, '_blank')
    },
    async mailchimpadd (k, o, cart_id) {
      debugger
      let _this = this
      let objInfo = {
        goods_id: k,
        goods_num: 1,
        product_id: 0,
        suppliers_id: o,
        cart_id: cart_id
      }
      let { data, code, msg } = await _this.$api.auyFun.MAILCHIMPADD(objInfo)
    },
    async addCart (k, o, f, type) {
      let _this = this
      let objInfo = {
        goods_id: k,
        goods_num: 1,
        product_id: 0,
        suppliers_id: o
      }
      let { status, data } = await _this.$api.auyFun.GET_ADDCART(objInfo)
      if (data.code != 200) {
        message.warning(data.msg)
      } else {
        // _this.mailchimpadd(k, o, data.data.cart_id)
        let datobj = {
          goods_id: objInfo.goods_id,
          goods_num: objInfo.goods_num,
          product_id: objInfo.product_id,
          suppliers_id: objInfo.suppliers_id,
          cart_id: data.data.cart_id
        }
        _this.mataStatistics(datobj)
        _this.$emit('update', data.data)
        _this.$Bus.$emit('ookkk', data.data.cart_num)
        message.success(data.msg)
      }
    },
    mataStatistics (datobj) {
      var self = this
      let em = self.$sha256(self.userInfo.email)
      let ph = self.$sha256(self.userInfo.mobile_phone)
      let userAgent = navigator.userAgent;
      let clientIpAddress = self.userInfo.ip;
      let timestamp = Math.floor(new Date().getTime() / 1000)
      self.$axios({
        method: "post",
        url: "https://graph.facebook.com/v17.0/832685061480710/events?access_token=EAAYolWRudJgBO69UKBuKqn9GqCsXpP3ccQYbn2l4IDiuGMTc7ZB4qZBxP68nZAAngeRS1dkfSpOuYVt3H3OYXVb2xI6apQF6ZCmZBNxdYZAQ7tPHcdoz0lar0uZAyZALqXA3geb5xKFYMMhunrMg1R421v8CGkKbLLNVrBGM1mZCNtPnPtZCVB0J45TXrddQu2chFPXAZDZD",
        data: {
          "data": [
            {
              "event_name": "AddToCart",
              "event_time": timestamp,
              "action_source": "website",
              "event_id": datobj.cart_id,
              "user_data": {
                "em": em,
                "ph": ph,
                // "client_ip_address": "254.254.254.254", "client_user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:63.0) Gecko/20100101 Firefox/63.0"
                "client_ip_address": clientIpAddress,
                "client_user_agent": userAgent
              },
              "custom_data": {
                "content_ids": datobj.goods_id,
                "content_num": datobj.goods_num,
                "content_product_id": datobj.product_id,
                "content_suppliers_id": datobj.suppliers_id
              }
            },
          ],
          "test_event_code": self.$TESTCODE
        },
      }).then((res) => {
        console.log(res.data);
      })
    },
    //添加购物车
    addTOcatr (j, k, o, f, type) {
      if (j == 1) {
        this.getShopDetail(k, f, type)
      } else {
        this.addCart(k, o, f, type)
      }
    },
    getShopDetail (k, f, type) {
      var self = this
      var data = {
        goods_id: k,
      }
      this.$axios({
        url: "/pc/goods/goodsSku",
        method: 'get',
        params: data
      })
        .then((res) => {
          if (res.code == 200) {
            self.simulated = res.data
            self.simulated.difference = res.data.product_list
            res.data.format.forEach((j, k) => {
              j.show_name = ''
            })
            self.simulated.specifications = res.data.format
            self.visible2 = true
            self.fname = f
            self.ftype = type
            // self.contData = res.data;
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    // addTOcatr () { },
    teshu (key) {
      debugger
      // this.$router.push(k)
      if (this.upName == "premier") {
        this.$router.push("categories/" + key.mark)
      } else if (this.upName == "fast_deliver") {
        // this.$customJump({
        //   path: '/content/classifyZone',
        //   query: { fast_deliver: 1 }
        // })
        this.$router.push("categories/" + key.mark)
      } else {
        let buryingPoint = {
          from_page: "index",
          current_page: "",
          area: "$10FreeShipping",
          id_value: "",
          value_type: ""
        }
        this.addaction(buryingPoint)
        this.$customJump({
          path: '/content/classifyZone',
          query: { specialoffer: 1 }
        })
      }

    },
    //首页记录埋点
    tosharemeccle () {
      this.$customJump({
        path: '/content/cash',
      })
    },
    async addaction (buryingPoint) {
      await this.$api.ADDACTION(buryingPoint).then((res) => {
      })
    },
  }
}
