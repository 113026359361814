import axios from 'axios'
import device from '../utils/device'

/**
 * 中间件 判断是不是爬虫
 */
function convertString(str) {
  const parts = str.split('-');
  const id = parts.pop();
  const productName = parts.join('-');
  return `${productName}?id=${id}`;
}
function activeString(str) {
  const parts = str.split('-');
  const id = parts.pop();
  const productName = parts.join('-');
  return `${productName}?active=${id}`;
}
export default function (context) {
  if ( context && context.req && context.req.headers ) {
    // 解析cookie
 if(context.req.headers.cookie){
 const parsedCookie = require('cookie').parse(context.req.headers.cookie);
    // 这里你可以访问parsedCookie对象来获取cookie值
  let token = parsedCookie['myCookie'];
    // 将解析后的cookie存储在req对象上，以便在后续的中间件或页面中使用
    context.req.cookie = token;
    
    if(token){
      axios.defaults.headers['token'] = token
    }
  }
// axios.defaults.headers['authorization'] = ''
}
  const { req, res } = context;
  if (!req) {
      // 如果没有请求对象（例如在客户端渲染某些场景下），则尝试从浏览器的window对象获取相关信息
      const { hostname } = window.location;
      const mobileDomain = 'http://m.meccle.com';
      const productPathRegex = /^\/products\//;
      const categoriesPathRegex = /^\/categories\//;
      const currentPath = window.location.pathname;

      if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          // 非移动端设备，这里可以添加其他逻辑，如果不需要处理则可省略
      } else {
          if (productPathRegex.test(currentPath)) {
              const parts = currentPath.split('/products/');
              const productContent = parts[1];
              var newString = convertString(productContent);
              window.location.href = mobileDomain + '/products/' + newString;
          } else if (categoriesPathRegex.test(currentPath)) {
              const partscategories = currentPath.split('/categories/');
              const categoriesContent = partscategories[1];
              var acvString = activeString(categoriesContent);
              window.location.href = mobileDomain + '/categories/' + acvString;
          } else {
              window.location.href = mobileDomain;
          }
      }
  } else {
    const { hostname } = req.headers.host;
    const mobileDomain = 'http://m.meccle.com';
    const productPathRegex = /^\/products\//;
    const categoriesPathRegex = /^\/categories\//;
    const currentPath = req.url;

// 判断是否为移动端设备
const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(req.headers['user-agent']);

if (isMobile) {
    // 判断路径并进行重定向
    if (productPathRegex.test(currentPath)) {
        const productContent = currentPath.split('/products/')[1];
        const newString = convertString(productContent);
        res.writeHead(302, { 'Location': `${mobileDomain}/products/${newString}` });
        res.end();
        return;  // 确保在发送响应后停止执行
    } else if (categoriesPathRegex.test(currentPath)) {
        const categoriesContent = currentPath.split('/categories/')[1];
        const acvString = activeString(categoriesContent);
        res.writeHead(302, { 'Location': `${mobileDomain}/categories/${acvString}` });
        res.end();
        return;  // 确保在发送响应后停止执行
    } else {
        // 如果路径不匹配任何规则，重定向到移动端首页
        res.writeHead(302, { 'Location': mobileDomain });
        res.end();
        return;  // 确保在发送响应后停止执行
    }
} else {
    // 非移动端设备，保持原样，或者可以加一些其他的处理逻辑
    // 例如：res.writeHead(200, { 'Content-Type': 'text/plain' });
    // res.end('This is a non-mobile user.');
    return;  // 确保不重复执行任何操作
}

      // // 如果有请求对象（服务器端渲染场景下），则从请求对象获取相关信息
      // const { hostname } = req.headers.host;
      // const mobileDomain = 'http://m.meccle.com';
      // const productPathRegex = /^\/products\//;
      // const categoriesPathRegex = /^\/categories\//;
      // const currentPath = req.url;

      // if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(req.headers['user-agent'])) {
      //     // 非移动端设备，这里可以添加其他逻辑，如果不需要处理则可省略
      // } else {
      //     if (productPathRegex.test(currentPath)) {
      //         const parts = currentPath.split('/products/');
      //         const productContent = parts[1];
      //         var newString = convertString(productContent);
      //         res.writeHead(302, { 'Location': mobileDomain + '/products/' + newString });
      //         res.end();
      //     } else if (categoriesPathRegex.test(currentPath)) {
      //         const partscategories = currentPath.split('/categories/');
      //         const categoriesContent = partscategories[1];
      //         var acvString = activeString(categoriesContent);
      //         res.writeHead(302, { 'Location': mobileDomain + '/categories/' + acvString });
      //         res.end();
      //     } else {
      //         res.writeHead(302, { 'Location': mobileDomain });
      //         res.end();
      //     }
      // }
  }

  return context;
}
// export default function(context) {
//   debugger
//   context.userAgent = process.server
//   ? context.req.headers['user-agent']
//   : navigator.userAgent
//   console.log(context.req,"909099")
//   // if(device.default.isMobile){
//   //   console.log(device.default.isMobile)
//   // }
//   if ( context && context.req && context.req.headers ) {
//         // 解析cookie
        
//      if(context.req.headers.cookie){
//      const parsedCookie = require('cookie').parse(context.req.headers.cookie);
//         // 这里你可以访问parsedCookie对象来获取cookie值
//       let token = parsedCookie['myCookie'];
//         // 将解析后的cookie存储在req对象上，以便在后续的中间件或页面中使用
//         context.req.cookie = token;
        
//         if(token){
//           axios.defaults.headers['token'] = token
//         }
//       }
//     axios.defaults.headers['authorization'] = ''
//   }
// }
