
// import Vue from 'vue'
// import fly from '/js/linyer'
import alertone from '@/components/alertbox/alertone'
import alerttwo from '@/components/alertbox/alerttwo'
import luckDzpLogin from '@/components/alertPop/luckDzpLogin.vue'
import { mapActions, mapState } from 'vuex'
import Cookies from '@/utils/cookie'
// import countdown from '~components/countdown/countdown.vue'
// const sha256 = require("js-sha256").sha256;
export default {
  name: 'horizontal',
  props: {
    // zdiBj: String,
    hide30: {
      type: Boolean,
      default: true
    },
    searchTips: {
      type: String,
      default: "Search here..."
    },
  },
  components: { alerttwo, alertone, luckDzpLogin },
  data () {
    return {
      popList: {
        one: {},
        two: {},
        three: {},
        four: {}
      },
      is_coupon_index: 0,
      indexDzptime: 0,
      SearchTips1: "Search here...",
      centerDialogVisible: false,
      pop_img: "",
      meccle_gifts: {
        end_time: 0,
        jian: 0
      },
      open_api_auth_token: "",
      googleBol: false,
      address: [],
      ad_active: 999,
      visible: true,
      sign_alert: true,
      signSshow: true,
      munList: {
        munTip: {
          name: "My Account",
          list: [
            {
              lab: "My account",
              path: "/account"
            },
            {
              lab: "My orders",
              path: "/order"
            },
            {
              lab: "My addresses",
              path: "/address/2"
            },
            // {
            //   lab: "Coupon",
            //   path: "/discount"
            // },
            {
              lab: "Meccle Business Club",
              path: "/FactoryMember"
            },
            {
              lab: "Meccle Shares",
              path: "/haoMeccle"
            },
            {
              lab: "Give 30% OFF & EARN 10%",
              path: "/Recommendation"
            },
            {
              lab: "Change my password ",
              path: "/replacement"
            },
            {
              lab: "Delete account",
              path: "/AccountCancellation"
            },

          ]
        },
        yourTip: {
          name: "Your Lists",
          list: [
            {
              lab: "My wishlist",
              path: "/storeCollect"
            },
            {
              lab: "My favorite store",
              path: "/storeCollect"
            }
          ]
        },
        signTip: {
          name: "Sign out",
        }
      },
      true1: true,
      zdiBj: "#009946",
      zdiBj1: "#097A3C",
      restaurants: [],
      state: this.$route.query.goods_name,
      timeout: null,
      hisSearch: false,
      passIn: '',
      cat_id: 0,
      activeIndex2: '1',
      classification: [],
      classdays: [],
      classdaysBOE: false,
      classfact: [],
      classfactBOE: false,
      // userInfo: {},
      userInfoTwo: true,
      userInfo: {},
      son_cat_name: '',
      classSon: [],
      input3: '',
      active: 0,
      current: 0,
      select: '',
      drawer: false,
      innerDrawer: false,
      hisJudge: false,
      direction: 'ltr',
      historyList: {
        goods_history: []
      },
      UUID: '',
      navbarList: [],
      listData: {
        pageIndex: '0'
      },
      indextoivobj: {},
      alertNum: 1,//默认登录弹窗显示
      parentClientWidth: '',
      opacity: 1,
      previousScroll: 0,
      appCode: "",
      appCity: "",
      fullHeigth: ""
      // fullHeigth: window.innerHeight
      //   ? window.innerHeight
      //   : document.documentElement && document.documentElement.clientHeight
      //     ? document.documentElement.clientHeight
      //     : document.body.offsetHeight
    }
  },
  computed: {
    ...mapState('addressindex', ['addressCode']),
    ...mapState('addressindex', ['addressCode']),

  },
  created () {

    this.indextoiv()
    // // this.GETCOFIGAB()
    // if (this.$storage.getLocalStorage('uuid')) {
    //   this.UUID = this.$storage.getLocalStorage('uuid')
    // } else {
    //   this.$storage.setLocalStorage('uuid', this.$uuid)
    //   this.UUID = this.$uuid
    // }
    // if (this.$storage.getLocalStorage('Applycode')) {
    //   this.appCode = this.$storage.getLocalStorage('Applycode')
    //   this.appCity = this.$storage.getLocalStorage('Applycity')
    // } else {
    // }
    this.defautaddress()
    // this.navbar()
    this.navbar()
    this.getHistoryList()
    // if (!sessionStorage.getItem("userInfo")) {
    // this.getHistoryList()
    this.restaurants = this.loadAll()
    // }
    //
  },
  destroyed () {
    // this.$refs["container"].removeEventListener("scroll", this.handleScroll1);
    window.removeEventListener('scroll', this.handleScroll1);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted () {
    // 在客户端渲染时读取 localStorage
    if (process.client) {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      this.autoexp()
      if (localStorage.getItem('userInfo') != "undefined") {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {}
      }
      // if (localStorage.getItem('newuuid')) {
      // } else {
      //   this.getUUid()
      // }
      window.addEventListener("scroll", this.handleScroll1);
      this.UUID = this.$storage.getLocalStorage('newuuid')
      this.fullHeigth = window.innerHeight
        ? window.innerHeight
        : document.documentElement && document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : document.body.offsetHeight
      this.$Bus.$on('ookkk', (da) => {
        this.userInfo.cart_num = da
      })
      // this.$Bus.$on('solltop', (da) => {
      //   this.opacity = 1
      // })
      this.$Bus.$on('closeBg', (da) => {
        this.hisSearch = false
        this.searchTips1 = da
      })
    }
    // this.$nextTick(() => {
    //   this.$refs["container"].addEventListener("scroll", this.handleScroll1);
    // });
    // window.addEventListener("scroll", this.handleScroll1);
    this.getUser()
    var self = this
    // window.onresize = function () {
    //   self.fullHeigth = window.innerHeight
    //     ? window.innerHeight
    //     : document.documentElement && document.documentElement.clientHeight
    //       ? document.documentElement.clientHeight
    //       : document.body.offsetHeight
    // }
    // 每隔半小时（30分钟）执行一次提示
    // setInterval(() => {
    //   self.showReminder()// 调用显示提示的方法
    // }, 1 * 10 * 1000); // 30分钟 = 30 * 60秒 * 1000毫秒
    // this.showPopup()
    // window.addEventListener("scroll", this.scrollHandle);
    // fullHeigth
  },
  watch: {
    fullHeigth: {
      immediate: true,
      handler: function (na) { }
    },
    parentClientWidth: {
      immediate: true,
      handler: function (cn) { }
    },
    "searchTips": {
      immediate: true,
      handler (newV, oldV) {
        this.searchTips1 = newV
      },
      deep: true
    },
    // hisJudge: {
    //   immediate: true,
    //   handler (newV, oldV) {
    //     if (newV) {
    //       this.getHistoryList()
    //     }
    //     // this.value = newV
    //   },
    //   deep: true
    // },
    "addressCode": {
      immediate: true,
      handler (newV, oldV) {
        if (newV) {
          if (newV.appCode != "" || newV.address_id != "") {
            this.appCode = newV.Applycode
            this.appCity = newV.Applycity
            if (newV.address_id != "" && newV.address_id) {
              this.setaddress(newV.address_id)
            }
          } else {

          }
        } else {

        }

        // this.value = newV
      },
      deep: true
    },
  },
  methods: {
    ...mapActions('loginInfo', ['SETLOGIN', 'SETACTIVE']),
    // ...mapActions('shopCart', ['SETHISTORYLIST', 'SETUSERINFO', 'SETTABLIST']),
    // ...mapActions('loginInfo', ['SETLOGIN']),
    handleBeforeUnload (event) {
      Cookies.remove('coupon_bol');
      // 在这里可以进行一些判断或者提示操作
      // 例如，可以弹出一个确认框询问用户是否真的要关闭网页

    },
    //优惠卷倒计时
    updateindex (data) {
      if (data.timeNum == 0) {
        this.autoexp(data.index)
      }
    },
    async autoexp (k) {
      let centerDialogVisibletime = this.$storage.getLocalStorage('centerDialogVisibletime')
      var self = this
      let objInfo = {
        coupon_log_id: k ? k : 0,
      }
      self.meccle_gifts = {}
      let { data } = await self.$api.auyFun.AUTOEXP(objInfo)
      if (data.code == 200) {
        self.meccle_gifts = data.data
        self.$set(self, 'meccle_gifts', data.data)
        if (data.data.type == "time") {
          data.data.end_time = Number(data.data.end_time)
          self.indexDzptime = data.data.end_time
          if (data.data.end_time > 0 && centerDialogVisibletime != 1) {
          } else {
            self.centerDialogVisible = false
          }
        }
      }
      // await self.$api.auyFun.AUTOEXP(objInfo).then((res) => {
      //   debugger

      // })
    },
    centerDialogVisibleok () {
      this.centerDialogVisible = false
      this.$storage.setLocalStorage('centerDialogVisibletime', 1)
    },
    async getUUid (k, j) {
      var self = this
      let { data, code, msg } = await auyFun.GETUSERUUID()
      if (code == 200) {
        localStorage.setItem('newuuid', data.uuid)
      }
    },
    // 监听页面滚动
    handleScroll1 () {
      // 获取当前的滚动距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断滚动条向上还是向下滚动
      // if (window.scrollY > this.previousScroll + 100) {
      //   this.opacity = 0
      // } else {
      //   this.opacity = 1
      //   // console.log('向上滚动');
      // }
      // console.log(window.scrollY, this.previousScroll)
      // if (window.scrollY == 0) {
      //   this.previousScroll = window.scrollY
      // } else {
      //   this.previousScroll = window.scrollY - 1;
      // }
      if (scrollTop > 150) {
        if (window.scrollY > this.previousScroll) {
          this.opacity = 0
        } else {
          this.opacity = 1
          // console.log('向上滚动');
        }
        this.previousScroll = window.scrollY - 1;
      }
      if (scrollTop < 150) {
        this.opacity = 1
      }
      // console.log(scrollTop, this.previousScroll)
    },
    open1 () {
      const h = this.$createElement;
      this.$notify({
        title: '标题名称',
        message: h('i', { style: 'color: teal' }, '这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案')
      });
    },
    showReminder () {
      // 在这里编写显示提示的逻辑，例如使用浏览器原生的Notification API
      if (Notification.permission === "granted") {
        new Notification("提醒", {
          body: "半小时已经过去了，别忘了休息一下！"
        });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            new Notification("提醒", {
              body: "半小时已经过去了，别忘了休息一下！"
            });
          }
        });
      }
    },
    tozone (k) {

      if (k == 1) {
        //2-4
        this.SearchTips1 = "Search 2-4 days delivery"
      } else if (k == 0) {
        //9-15
        this.SearchTips1 = "Search 9-21 days delivery"
      }
      this.drawer = !this.drawer
      this.$customJump({
        path: '/content/classifyZone',
        query: { is_oversea: k }
      })
    },

    //free_open
    free_open () {
      this.$alert(this.indextoivobj.content, this.indextoivobj.title, {
        center: true,
        confirmButtonClass: 'btnFalses',
        confirmButtonText: 'OK',
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${action}`
          // });
        }
      });
    },
    todisconupt () {
      this.$customJump({
        path: '/content/discount',
      })
    },
    // 通知
    async indextoiv (queryString) {
      let self = this
      let { data, code } = await self.$api.auyFun.INDEXNOTIC()
      // if (code == 200) {

      this.indextoivobj = data.data
      // this.querySearchAsync()
      // }
    },
    //删除搜索历史
    delCheck (k) {

      this.DELseach(k.value)
    },
    async DELseach (queryString) {
      var objInfo = {
        keywords: queryString,
        uuid: this.UUID
      }
      var _this = this

      let { data, code } = await _this.$api.auyFun.DELSEACHLOG(objInfo)
      if (data.code == 200) {
        _this.hisSearch = false
        _this.restaurants.forEach((j, k) => {
          if (queryString == j.value) {
            _this.restaurants.splice(k, 1)
          }
        })

        // this.querySearchAsync()
      }
    },
    //点击展开首页分类
    drawerFun () {
      this.getCategory()
      this.drawer = true
    },
    vibFun (de) {
      if (de && this.classification.length <= 0) {
        this.getCategory()
      }
    },
    gettest () {
      // 获取用户地址
      var self = this
      let orderInfo = {
        params_json: JSON.stringify(self.form)
      }
      let link = "http://192.168.110.116:8093/open/open_api_v1/log_in"
      self.$axios({
        method: "post",
        url: link,
        // data: qs.stringify(orderInfo)
        data: {
          "email": "236371812@qq.com",
          "password": "PSJ987987"
        }
        ,
        headers: { "device": "pc", 'Content-Type': 'application/json' }
      }).then((res) => {
        // password = sha256('my password');
        self.open_api_auth_token = res.open_api_auth_token
        self.postText()
      })
    },
    postText () {
      let email = "236371812@qq.com"
      let open_api_token = this.open_api_auth_token
      var time = Date.parse(new Date()).toString().substring(0, 10);
      let timestamp = time
      let nonce = time + "1"
      let sign_version = "v2"
      let signAfter = email + "&" + open_api_token + "&" + timestamp + "&" + nonce + "&" + sign_version
      // let signTer = open_api_token + "&nonce=" + nonce + "&sign_version=v2"
      let sign = sha256(signAfter)
      // console.log(sign)
      var self = this
      let orderInfo = {
        params_json: JSON.stringify(self.form)
      }
      // let datap = {
      //   "type": "email",
      //   "content": "1506396446@qq.com",
      //   "order": {
      //     "order_no": "20190510",
      //     "name": "Apple iPhone X (A1903) 64GB",
      //     "url": "xxx",
      //     "price": 8888.8,
      //     "order_at": "2019-05-10 10:10:10",
      //     "pay_at": "2019-05-10 10:15:10",
      //     "status": "paid",
      //     "remark": "xxxx",
      //     "consignee_name": "张三",
      //     "consignee_phone": "01012345",
      //     "consignee_address": "北京市大兴区",
      //     "commodit_num": 88,
      //     "commodities": [
      //       {
      //         "commodit_name": "牛奶A",
      //         "commodit_no": "NO123456",
      //         "commodit_count": 1,
      //         "commodit_fee": "46.5"
      //       }
      //     ]
      //   }
      // }
      let link = "http://192.168.110.116:8093/open/open_api_v1/customer_orders?email=236371812@qq.com&timestamp="
        + timestamp + "&sign=" + sign + "&nonce=" + nonce + "&sign_version=v2"
      self.$axios({
        method: "post",
        url: link,
        // data: qs.stringify(orderInfo)
        data: {
          "type": "id",
          "content": "4286973901",
          "order": {
            "order_no": "T2023051615948",
            "name": "Apple iPhone X (A1903) 64GB",
            "url": "xxx",
            "price": 8888.8,
            "order_at": "2019-05-10 10:10:10",
            "pay_at": "2019-05-10 10:15:10",
            "status": "paid",
            "remark": "xxxx",
            "consignee_name": "张三",
            "consignee_phone": "01012345",
            "consignee_address": "北京市大兴区",
            "commodit_num": 88,
            "commodities": [
              {
                "commodit_name": "牛奶A",
                "commodit_no": "NO123456",
                "commodit_count": 1,
                "commodit_fee": "46.5"
              }
            ]
          }
        }
        ,
        headers: { "device": "pc", 'Content-Type': 'application/json' }
      }).then((res) => {
        // password = sha256('my password');
        // console.log(res.open_api_auth_token);
        // self.open_api_auth_token = res.open_api_auth_token
        // self.postText()
      })
    },
    upShow (code, city) {
      this.appCode = code
      this.appCity = city
      // this.visible = false
      // this.getShopDetail()
    },
    selectFun (ko) {
      this.classification.forEach((j, k) => {
        if (k == ko) {
          this.cat_id = j.cat_id
        }
      })
    },
    async defautaddress () {
      let { data } = await this.$api.auyFun.DEFAULTADDRESS()
      if (data.data) {
        this.appCity = data.data.city_name
        this.appCode = data.data.post_code
      }
    },
    async setaddress (address_id) {
      let info = {
        address_id: address_id
      }
      let { data } = await this.$api.auyFun.SETSELECTADDRESS(info)
      if (data) {
        // this.appCity = data.city_name
        // this.appCode = data.post_code
      }
    },
    addressList () {
      // 获取用户地址
      this.$axios({
        url: "/pc/address/list",
        method: 'get'
      }).then((res) => {
        if (res) {
          if (res.data) {
            res.data.forEach((j, k) => {
              if (j.is_select == 1) {
                this.ad_active = k
              }
            })
          }
          this.address = res.data
          if (this.address.length != 0) {
            this.$refs['screenChildhear'].check()
          } else {
            this.$refs['screenChild1hear'].check()
          }
        }
      })
    },
    showAlert1 () {
      // 
      // this.$Bus.$emit('detailo', )
      this.addressList()

    },

    upAddress (id) {
      this.address_id = id
    },
    showPopup () {
      // this.sign_alert = this.true
      this.alertNum++
      if (this.alertNum > 3) {
        // this.sign_alert = false
      }
      // this.$refs.gogo.doShow()
      // this.sign_alert = false

    },
    loadAll () {
      return [
        { value: '三全鲜食（北新泾店）', address: '长宁区新渔路144号' },
        { value: 'Hot honey 首尔炸鸡（仙霞路）', address: '上海市长宁区淞虹路661号' },
        { value: '新旺角茶餐厅', address: '上海市普陀区真北路988号创邑金沙谷6号楼113' },
        { value: '泷千家(天山西路店)', address: '天山西路438号' }
      ]
    },
    seachFun () {
      this.hisSearch = false
      if (this.$route.path == "/factorZone") {
        this.$Bus.$emit('GetGoodsfat', '1')
        // this.$customJump({
        //   path: '/factorZone',
        //   query: { cat_id: this.cat_id, goods_name: this.state }
        // })
      } else if (this.$route.path == "/classifyZone") {
        if (this.$route.query.is_oversea == 1) {
          this.$customJump({
            path: '/content/classifyZone',
            query: { cat_id: this.cat_id, goods_name: this.state, is_oversea: 1 }
          })
        } else {
          this.$customJump({
            path: '/content/classifyZone',
            query: { cat_id: this.cat_id, goods_name: this.state, is_oversea: 0 }
          })
        }
      } else {
        let keywordslist = this.restaurants[0].suggestKeyword
        var routeData = this.$router.resolve({
          path: '/categories',
          query: { goods_name: this.state }
        })
        window.open(routeData.href, '_blank')
        // this.$customJump({
        //   path: '/content/classify',
        //   query: { cat_id: this.cat_id, goods_name: this.state, keywordslist: keywordslist }
        // })
      }

    },
    //选择下拉
    handleSelect2 (item) {
      this.hisSearch = false
      if (this.$route.path == "/factorZone") {
        this.$customJump({
          query: merge(this.$route.query, { goods_name: item.value }),
        });
        // this.$customJump({
        //   path: '/factorZone',
        //   query: { cat_id: this.cat_id, goods_name: item.value }
        // })
        var _this = this
        setTimeout(() => {
          _this.$Bus.$emit('GetGoodsfat', '1')
        }, 1000)

      } else if (this.$route.path == "/classifyZone") {

        if (this.$route.query.is_oversea == 1) {
          this.$customJump({
            path: '/content/classifyZone',
            query: { cat_id: this.cat_id, goods_name: item.value, is_oversea: 1 }
          })
        } else {
          this.$customJump({
            path: '/content/classifyZone',
            query: { cat_id: this.cat_id, goods_name: item.value, is_oversea: 0 }
          })
        }
      } else {
        this.$router.push({
          path: '/categories',
          query: { goods_name: item.value }
        })
        // this.$customJump({
        //   path: '/content/classify',
        //   query: { cat_id: this.cat_id, goods_name: item.value }
        // })
      }


    },
    //删除搜索内容跟新地址关键词
    blurForBug (k) {
      this.hisSearch = false
      // this.$customJump({
      //   query: merge(this.$route.query, { goods_name: k }),
      // });
      // var _this = this
      // setTimeout(() => {
      //   _this.$Bus.$emit('GetGoodsfat', '1')
      // }, 1000)
      // 
      // 
      // this.$router.go(0)
    },

    //联想关键词
    // async querySearchAsync (queryString, cb) {
    //   var self = this
    //   if (this.$route.path == "/factorZone") {
    //     self.$customJump({
    //       query: merge(self.$route.query, { goods_name: queryString }),
    //     });
    //     var objInfo = {
    //       keywords: queryString,
    //       uuid: self.UUID,
    //       is_wholesale: 1
    //     }
    //   } else {
    //     var objInfo = {
    //       keywords: queryString,
    //       // uuid: self.UUID,
    //     }
    //   }
    //   this.$jsonp('https://open-s.alibaba.com/openservice/associationSuggestionViewService', objInfo).then(json => {
    //     // 返回数据 json， 返回的数据就是json格式
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    //联想关键词
    async querySearchAsync (queryString, cb) {
      var self = this
      if (this.$route.path == "/factorZone") {
        self.$customJump({
          query: merge(self.$route.query, { goods_name: queryString }),
        });
        var objInfo = {
          keywords: queryString,
          uuid: self.UUID,
          is_wholesale: 1
        }
      } else {
        var objInfo = {
          keywords: queryString,
          uuid: self.UUID,
        }
      }
      // KEYWORDS
      // ALIBABA
      let { data } = await this.$api.auyFun.ALIBABA(objInfo)
      // if (data.length > 0) {
      //   self.restaurants = data.slice(0, 9)
      //   cb(self.restaurants)
      // } else {
      //   self.timeout = setTimeout(() => {
      //     cb([])
      //   }, 1000 * Math.random())
      // }

      if (data.data) {
        if (data.data.list.length > 0) {
          data.data.list.forEach((j, k) => {
            j.value = j.suggestKeyword
          })
          self.restaurants = data.data.list.slice(0, 9)
          cb(self.restaurants)
        } else {
          self.timeout = setTimeout(() => {
            cb([])
          }, 1000 * Math.random())
        }
      }
    },
    createStateFilter (queryString) {
      return (state) => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },

    focus () {
      // console.log(this.$route.path, this.$route.query.is_oversea)
      this.hisSearch = true
      // this.hisJudge = !this.hisJudge
    },
    focuson () {
      this.hisSearch = false
    },

    //点击开始,请求接口抽奖
    async startPlay () {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.DZPDRAW()
      if (code != 200) {

      } else {
        self.indexft()
        // self.autoexp()
      }
    },
    async getUser () {
      var self = this
      var objInfo = {}
      let { data } = await self.$api.auyFun.GET_USERINFO(objInfo)
      if (data.data.length == 0) {
        data.data = {}
        self.googleBol = true
        self.munList.signTip.name = 'Sign in'
      } else {
        self.googleBol = false
        self.munList.signTip.name = 'Sign out'
        self.userInfo = data.data
        self.is_coupon_index = data.data.is_coupon
        if (data.data.is_coupon == 1) {
          self.startPlay()
        } else {
          self.indexft()
          self.autoexp()
        }
        // self.SETUSERINFO(data)
        // self.SETLOGIN(data)
        sessionStorage.setItem('coupon_bol', 1) //登录后不显示大转盘
        sessionStorage.setItem('userInfo', JSON.stringify(data.data))
      }
    },
    //首页弹窗显示
    async indexft () {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.INDEXGIFTPOP()
      if (data.code != 200) {

      } else {
        self.centerDialogVisible = true
        self.popList = data.data
        // self.autoexp()
      }
    },
    backHome () {
      var _this = this
      // _this.$Bus.$emit('brotherSaid', '1')
      setTimeout(() => {
        _this.$customJump('/')
      }, 500)
    },
    signOut () {
      this.$emit('signOut')
    },
    toItemlink (j, k) {
      this.$Bus.$emit('detailo', '1')
      // if (j == "")
      this.$customJump({
        path: "/content" + k
      })
    },

    YourProfile () {
      this.$customJump({
        path: "/content/history/YourProfile"
      })
    },
    tosstocolle (j) {
      this.$Bus.$emit('detailo', '1')
      if (j == "product") this.$storage.setLocalStorage('disNane', "first")
      if (j == "store") this.$storage.setLocalStorage('disNane', "second")
      this.$Bus.$emit('storeCollect', '1')
      this.$customJump({
        path: "/content/storeCollect"
      })
    },
    toLink (k) {
      this.$Bus.$emit('detailo', '1')
      var uuid = this.$storage.getLocalStorage('newuuid')
      var acconutShower = this.$storage.getLocalStorage('acconutShower')
      if (this.munList.signTip.name == 'Sign out') {
        if (process.client) {
          // 仅在客户端执行
          localStorage.clear()
          sessionStorage.clear();
          Cookies.remove('myCookie');
          Cookies.remove('userInfo');
          Cookies.remove('centerDialogVisibletime');
        }
        sessionStorage.setItem('coupon_bol', 1)
        this.$storage.setLocalStorage('newuuid', uuid)
        this.$storage.setLocalStorage('acconutShower', acconutShower)
        this.$router.go(0)
      } else {
        if (process.client) {
          // 仅在客户端执行
          localStorage.clear()
          sessionStorage.clear();
          Cookies.remove('myCookie');
          Cookies.remove('userInfo');
          Cookies.remove('centerDialogVisibletime');
        }
        sessionStorage.setItem('coupon_bol', 1)
        this.$storage.setLocalStorage('newuuid', uuid)
        this.$storage.setLocalStorage('acconutShower', acconutShower)
        this.SETACTIVE(true)
      }
    },
    handleSelect (key, keyPath) {
      if (key != 6) {
        let path = {
          1: '',
          2: '',
          3: '/content/order',
          4: '/content/shopCartNew'
        }
        this.$customJump({
          path: path[key]
        })
      }

      // console.log(key, keyPath);
    },
    change (type) {
      // if (type == "next") {
      //   var self = this;
      //   setTimeout(() => {
      //     self.listData.pageIndex = (
      //       parseInt(self.listData.pageIndex) + 1
      //     ).toString();
      //     this.getHistoryList();
      //   }, 100);
      // }
      this.$refs['flexSel'][type]()
    },
    toDetail (k) {
      this.$customJump({
        path: '/detail',
        query: { goods_id: k }
      })

    },
    toLogin () {
      // this.$customJump({
      // 	path: '/login',
      // 	query: {
      // 		redirect: this.$router.currentRoute.fullPath
      // 	}
      // })
    },
    // post
    test () {
      var self = this

      var data = {
        myID: myID
      }
      fly.AxiosC({
        // url:"https://app.bulkbuyworld.com/app/Login/doUserLogin",
        url: self.$api.HOTEL.getlogin,
        data: {
          data
        },
        success: (res) => {
          if (res.code == 200) {
            // this.$toast(res.msg);
          }
        }
      })
    },

    //首页菜单·
    navbar () {
      var self = this
      var data = {}
      //   data = fly.initData(data);
      this.$axios({
        url: "/pc/navbar",
        method: 'get',
        params: data
      })
        .then((res) => {
          if (res.code == 200) {
            this.navbarList = res.data
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },

    toenlink () {
      this.$Bus.$emit('detailo', '1')
      window.location.href = 'https://pc.bulkbuyworld.com/'
    },
    //历史记录
    async getHistoryList () {
      var self = this
      var objInfo = {
        // page: self.listData.pageIndex,
        page: 0,
        pageSize: '30'
      }
      let { data } = await self.$api.auyFun.GET_HISTORY(objInfo)
      if (self.listData.pageIndex > 0) {
        self.historyList.data.push(data.data)
      } else {
        self.historyList = data.data
        // self.more=false
      }
      // self.SETHISTORYLIST(self.historyList)

    },
    // /pc/indexCategory

    shopCategory () {
      var self = this
      var data = {
        is_page: 0
      }
      //   data = fly.initData(data);
      this.$axios({
        url: self.$api.HOTEL.shopCategory,
        method: 'get',
        params: data
      })
        .then((res) => {
          if (res.code == 200) {
            self.classification = res.data.category
            // self.classdays = res.data.oversea,
            //   self.classfact = res.data.factory,
            // self.SETTABLIST(res.data.category)
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    //首页分类
    getCategory () {

      var self = this
      var data = {
        is_page: 0
      }
      // self.SETTABLIST([])
      //   data = fly.initData(data);
      this.$axios({
        url: "/pc/indexCategory",
        method: 'get',
        params: data
      })
        .then((res) => {
          if (res.code == 200) {
            self.classification = res.data.category
            self.classdays = res.data.oversea
            if (res.data.oversea.length > 5) {
              self.classdaysBOE = true
            }
            self.classfact = res.data.factory
            if (res.data.factory.length > 5) {
              self.classfactBOE = true
            }
            // res.data.category.unshift({ cat_name: 'All' })
            // self.SETTABLIST(res.data.classfact)
            // self.SETTABLIST(res.data.category)
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    toClass (j) {
      if (this.current == 1) {
        this.current = 2
      } else {
        this.current = 1
      }
      this.drawer = !this.drawer
      this.$customJump({
        path: '/content/classify',
        query: { cat_id: j.cat_id }
      })
    },

    tofact () {
      this.$Bus.$emit('factor', '1')
      this.$customJump({
        path: '/content/factorZone',
      })
    },
    // toItemSet() {},
    toItem (i, eo, KK) {
      // this.getsidebar(i);
      if (KK == "F1") {

        this.drawer = !this.drawer
        this.$customJump({
          path: '/content/classifyZone',
          query: { is_oversea: 1, cat_id: eo.cat_id }

        })
      } else if (KK == "F2") {
        this.drawer = !this.drawer
        this.$Bus.$emit('factor', '1')
        this.$customJump({
          path: '/content/factorZone',
          query: { cat_id: eo.cat_id }
        })
      } else {
        if (this.current == 1) {
          this.current = 2
        } else {
          this.current = 1
        }
        this.parentClientWidth = this.$refs.barparent.clientWidth
        this.classification.forEach((j, k) => {
          if (k == i) {
            this.classSon = j.son_category
          }
        })
      }

      this.son_cat_name = eo.cat_name

      // console.log(this.parentClientWidth);
      // hmenu-nav-center
    },
    backTop (i) {
      // if (this.current == 1) {
      this.current = 2
      // } else {
      // 	this.current = 1
      // }
      // this.parentClientWidth = this.$refs.barparent.clientWidth
      // this.getsidebar(i);
      // console.log(this.parentClientWidth);
      // hmenu-nav-center
    },
    // scrollHandle(e) {
    //   ;
    //   let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
    //   console.log(top);
    // },
    mouseOver (k) {
      // setTimeout(() => {
      if (k == 'Browsing History') {
        this.hisJudge = true
        //
      } else {
        this.hisJudge = false
      }
      // }, 200)
    },
    mouseOver2 (k) {

      // if (!this.hisJudge) {
      if (k == 'Browsing History') {
        this.hisJudge = false
      }
      // }
    },
    mouseOver1 () {
      this.hisJudge = true
    },
    mouseleave () {
      this.hisJudge = !this.hisJudge

    },
    toHis (k, ri) {
      if (k.name == 'New Arrivals') {
        this.$customJump({
          path: '/content/daysDeals'
        })
      } else if (k.name == 'Customer Service') {
        this.$customJump({
          path: '/content/information'
        })
      }
      // else if (k.name == 'HAO') {
      //   this.$customJump({
      //     path: '/haoMeccle'
      //   })
      // }
      else if (k.name == 'Grand Opening') {
        // process.env.link_URL+"
        // window.location.href = "https://test2.bulkbuyworld.com/content/grandopening?store_id=" + k.other_value
        this.$customJump({
          path: '/content/grandopening',
          query: { store_id: k.other_value }
        })
      } else if (k.name == 'Buy Again') {
        this.$customJump({
          path: '/content/order'
        })
      } else if (k.name == 'Browsing History') {
        this.$customJump({
          path: '/content/history/MyBrowsingHistory'
        })
      } else if (k.name == 'My Account') {
        this.$customJump({
          path: '/content/account'
        })
      } else if (k.name == 'Sellers Hub') {
        // if (this.$storage.getLocalStorage('storetoken')) {
        this.$customJump({
          path: '/content/sellerCentral'
        })
        // } else {
        // 	this.$customJump({
        // 		path: '/sellSign'
        // 	})
        // }
      } else if (k.name == 'Download App') {
        window.open('https://download.meccle.com/', '_blank')
      } else if (k.name == 'Find Factories') {
        // this.$Bus.$emit('factor', '1')
        this.$customJump({
          path: '/content/factorZone'
        })
        // this.zhiying()
      }
      // if (k.name == 'Browsing History') {
      // 	this.$customJump({
      // 		path: '/history/MyBrowsingHistory'
      // 	})
      // } else if (k.name == 'sell') {
      // 	if (this.$storage.getLocalStorage('storetoken')) {
      // 		this.$customJump({
      // 			path: '/sellerCentral'
      // 		})
      // 	} else {
      // 		this.$customJump({
      // 			path: '/sellSign'
      // 		})
      // 	}
      // } else if (k.name == 'Days deals') {
      // 		this.$customJump({
      // 		path: '/daysDeals'
      // 	})
      // } else if (k.name == 'Buy Again') {
      // 	this.$customJump({
      // 		path: '/order'
      // 	})
      // } else if (k.name == 'wanlis Pingshile') {
      // 	this.$customJump({
      // 		path: '/history/MyFranakshaus'
      // 	})
      // }
    },
    zhiying () {
      var routeData = this.$router.resolve({
        path: '/content/fourStep'
      })
      window.open(routeData.href, '_blank')
      // this.$customJump({
      // 	path: '/fourStep'
      // })
    },

    handleClose (done) {
      this.backTop()
      done()
      // this.$confirm("还有未保存的工作哦确定关闭吗？")
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
    handleScroll () { },
    async GETCOFIGAB () {
      var self = this
      let orderInfo = {
        key: 'THEME_COLOR'
      }

      let { data, code, msg } = await self.$api.auyFun.GETCOFIGAB(orderInfo)
      if (code == 200) {
        window.this.$storage.setLocalStorage('zdiBj', data.content)
        window.this.$storage.setLocalStorage('zdiBj1', data.value)
        self.zdiBj = data.content
        self.zdiBj1 = data.value
      } else {
      }
    }
  },

}
