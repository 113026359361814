
import facebook from '@/components/login/facebook/facebook'
import twitter from '@/components/login/twitter/twitter'
import Cookies from '@/utils/cookie'
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    sactive: {
      type: Boolean,
      default: false
    }
  },
  components: { facebook, twitter },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('enter your PIN'))
      } else if (value.length < 6) {
        callback(new Error('The password must contain at least six characters'))
      } else {
        callback()
      }
    }
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
      if (!value) {
        return callback(new Error('The email cannot be empty'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter the correct email format'))
        }
      }, 100)
    }
    // 、、注册
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('The name cannot be empty'))
      } else callback()
    }
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please fill in your password'))
      } else if (value.length < 6) {
        callback(new Error('The password must contain at least six characters'))
      } else {
        if (this.ruleForm1.checkPass !== '') {
          this.$refs.ruleForm1.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your password here again'))
      } else if (value !== this.ruleForm1.pass) {
        callback(new Error('The passwords are inconsistent'))
      } else {
        callback()
      }
    }
    var checkEmail1 = (rule, value, callback) => {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
      if (!value) {
        return callback(new Error('The email cannot be empty'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter the correct email format'))
        }
      }, 100)
    }
    var checkValidation = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please verify security first'))
      } else if (value.length < 5) {
        callback(new Error('Enter the correct verification code'))
      } else {
        if (this.ruleForm1.checkPass !== '') {
          this.$refs.ruleForm1.validateField('checkPass')
        }
        callback()
      }
    }
    return {
      visible: false,
      visiblelogin: false,//登录
      visibleregister: false,//注册
      visibleChange: false,//忘记密码
      Applycode: "",
      plz: false,
      city: "",
      chectBol: true,
      city_name: "",
      post_code: "",
      showfa: 1,
      other_login_img: [],
      ruleForm: {
        pass: '',
        email: ''
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur', required: true }],
        email: [{ validator: checkEmail, trigger: 'blur', required: true }]
      },
      falgg: true,
      dialogVisible: false, // 用户协议展示框
      agreement: '',
      randomCode: 0,
      timer: null,
      time: '',
      flag: true,
      ruleForm1: {
        pass: '',
        checkPass: '',
        invit_code: '',
        email: '',
        validation: '',
        code: ''
      },
      rules1: {
        pass: [{ validator: validatePass1, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        name: [{ validator: checkName, trigger: 'blur' }],
        email: [{ validator: checkEmail1, trigger: 'blur' }],
        validation: [{ validator: checkValidation, trigger: 'blur' }]
      },
      ruleForm2: {
        password: '',
        email: '',
        code: '',
        validation: ''
      },
    }
  },
  computed: {
    only () {
      return Math.round(Math.random() * Date.parse(new Date()))
    }
  },
  created () {
    // if (localStorage.getItem('Applycity')) {
    //   this.chectBol = false
    // }
  },
  mounted () {
    this.othersign()
    if (process.client) {
      this.Applycode = localStorage.getItem('Applycode') ? localStorage.getItem('Applycode') : ""
      if (localStorage.getItem('Applycity')) {
        this.chectBol = false
      }
      if (localStorage.getItem('userInfo')) {
        this.showfa = 1
      } else {
        this.showfa = 2
      }
    }
  },
  watch: {
    'sactive': {
      handler (newV, oldV) {
        this.visible = newV
      },
      deep: true
    },
  },
  methods: {
    ...mapActions('addressindex', ['SETADDRESSCODE']),
    ...mapActions('loginInfo', ['SETLOGIN', 'SETACTIVE']),
    handleClose (k) {
      this.SETACTIVE(false)
    },
    handleGoBack () {
      this.visiblelogin = false//登录
      this.visibleregister = false//注册
      this.visibleChange = false//忘记密码
      this.visible = true
    },
    //获取uuid
    async getUUid (k, j) {
      var self = this
      let { data, code, msg } = await self.$api.auyFun.GETUSERUUID()
      if (data.code == 200) {
        localStorage.setItem('newuuid', data.data.uuid)
      }
    },
    //注册
    caradSHow () {
      this.visiblelogin = false
      this.visibleregister = true
    },
    register () {
      let data = {
        email: this.ruleForm1.email,
        password: this.ruleForm1.pass,
        enter_password: this.ruleForm1.checkPass,
        invit_code: this.ruleForm1.invit_code,
        email_code: this.ruleForm1.code
      }
      this.$axios({
        url: "/pc/register",
        method: 'post',
        data: data,
      }).then((res) => {
        if (res) {
          if (res.code == 200) {
            // this.mailchimpedel(res.data.user.user_id, res.data.user.email)
            this.$message.success(res.msg)
            this.visibleregister = false
            this.visiblelogin = true
          } else this.$message.warning(res.msg)
        }
      })
    },
    getEmailCode () {
      if (this.falgg) {
        this.falgg = false
        this.$api.auyFun.EMAILCODE({
          email: this.ruleForm1.email,
          verify_code: this.ruleForm1.validation,
          id: this.only
        })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              const TIME_COUNT = 60
              this.flag = false
              if (!this.flag) {
                const mailReg =
                  /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
                if (mailReg.test(this.ruleForm1.email)) {
                } else
                  this.$message.warning('Please enter the correct email address')
                this.time = TIME_COUNT
                this.flag = false
                this.timer = setInterval(() => {
                  if (this.time > 0 && this.time <= TIME_COUNT) {
                    this.time--
                  } else {
                    this.falgg = true
                    this.flag = true
                    clearInterval(this.timer) // 清除定时器
                    this.timer = null
                  }
                }, 1000)
              }
            } else {
              this.falgg = true
              this.$message.warning(res.data.msg)
            }
          })
      }
    },
    //登录
    login () {
      var self = this
      let data = {
        email: self.ruleForm.email,
        password: self.ruleForm.pass
      }
      self.$axios({
        url: "/pc/login",
        method: 'post',
        data: data,
      }).then((res) => {
        if (res.code == 200) {
          self.getUUid()
          self.$message.success(res.msg)
          self.visiblelogin = false
          // self.SETLOGIN(res.data.user)
          localStorage.setItem('token', res.data.token)
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
          Cookies.set('myCookie', res.data.token, { expires: 14 });
          Cookies.set('userInfo', JSON.stringify(res.data.user), { expires: 14 });
          self.$router.go(0)
        } else {
          self.$message.warning(res.msg)
        }
      })
    },
    //忘记密码
    chagePas () {
      this.visiblelogin = false
      this.visibleChange = true
    },
    cheagelogin () {
      delete this.ruleForm2.validation
      this.$api.auyFun.EDITPWD(this.ruleForm2).then((res) => {
        debugger
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.visibleChange = false
          this.visiblelogin = true

        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    getEmailCode2 () {
      if (this.ruleForm2.email == "") {
        this.$message.warning("Please fill in the email address")
        return
      }
      const TIME_COUNT = 60
      this.flag = false

      if (!this.flag) {
        const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
        if (mailReg.test(this.ruleForm2.email)) {
          this.$api.auyFun.EMAILCODE({
            email: this.ruleForm2.email,
            verify_code: this.ruleForm2.validation,
            id: this.only
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.isdisabled = true
                this.$message.success(res.data.msg)
              } else this.$message.warning(res.data.msg)
            })
        } else this.$message.warning('Please enter the correct email address')
        this.time = TIME_COUNT
        this.flag = false
        this.timer = setInterval(() => {
          if (this.time > 0 && this.time <= TIME_COUNT) {
            this.time--
          } else {
            this.flag = true
            this.isdisabled = false
            clearInterval(this.timer) // 清除定时器
            this.timer = null
          }
        }, 1000)
      }
    },
    async othersign () {
      let objInfo = {}
      let { data, code, msg } = await this.$api.auyFun.OTHERLOGIN(objInfo)
      if (data.code == 200) {
        this.pc_switch = data.data.pc_switch
        this.other_login_img = data.data
      } else {
        // this.$this.$message.warning(msg)
      }
    },
    tolinks (k) {
      if (k == 'google') {
        // window.location.href = 'http://www.psj001.com/pc/authorization?platformType=3'
        this.client()
      } else if (k == 'facebook') {
        // this.iframeSrc = "https://www.psj001.com/pc/facebook"
      } else if (k == "em") {
        this.visible = false
        this.visiblelogin = true
      } else {
        // this.$message({
        //   this.$message: 'Under development',
        //   type: 'info'
        // })
        // this.$this.$message.info('Under development')
      }
    },
    async client () {
      let client
      var _this = this
      if (typeof (google) == "object") {
        client = await google.accounts.oauth2.initCodeClient({
          // client_id: '461018696142-pf6v6saoait4shvgr9s6ppgd3ncouqi7.apps.googleusercontent.com',
          client_id: '461876387606-prm04fca7gv2rek6k00hq83n4kub977t.apps.googleusercontent.com',
          // scope: 'profile email',
          scope: 'profile email',
          ux_mode: 'popup',
          callback: (response) => {
            // console.log(response)
            let obj = {
              device: "pc"
            }
            let response_info = {
              ...response, ...obj
            }
            _this.handleCredentialResponse(response_info)

          },
        });
        client.requestCode();
      }
    },
    async handleCredentialResponse (data) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      // let code = response.credential
      // JSON.stringify(options.params)
      // await api.googleLogin(code);
      // console.log(code)
      var self = this
      self.$axios({
        url: "/pc/codeGoogleLogin",
        method: 'post',
        data: data,
      }).then((res) => {
        if (res.code == 200) {
          self.getUUid()//获取uuid
          self.$message.success(res.data.msg)
          // self.SETLOGIN(res.data.data.user)
          localStorage.setItem('token', res.data.token)
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.user))
          Cookies.set('myCookie', res.data.token, { expires: 14 });
          Cookies.set('userInfo', JSON.stringify(res.data.user), { expires: 14 });
          self.visible = false
          self.$router.go(0)
        } else {
          self.$message.warning(res.data.msg)
        }
      })
    },


    Changeto () {
      this.chectBol = true
    },

    async codelist () {
      // var self = this
      // plz //显示zipcode错误提示
      //chectBol 显示输入的zipcode是否存在
      if (this.Applycode.length == 5) {
        var objInfo = {
          code: this.Applycode,
        }
        let { data } = await this.$api.auyFun.CODELIST(objInfo)
        if (data.data.length > 0) {
          this.chectBol = false
          this.city = data.data[0].city
          this.plz = false
          localStorage.setItem('Applycode', data.data[0].code)
          localStorage.setItem('Applycity', data.data[0].city)
        } else {
          this.plz = true
        }
      } else {
        this.plz = true
      }

    },
    check () {
      if (localStorage.getItem('Applycity')) {
        this.chectBol = false
        this.Applycode = localStorage.getItem('Applycode')
      }
      this.visible = !this.visible
    },
    toaddress () {
      this.$customJump({
        path: '/content/address/2'
      })
    },
    doneUp () {
      // this.$emit('upShow', localStorage.getItem('Applycode'), localStorage.getItem('Applycity'))
      // this.check()
      var _this = this
      _this.check()
      if (localStorage.getItem('Applycode')) {
        _this.$emit('upShow', localStorage.getItem('Applycode'), localStorage.getItem('Applycity'))
        var obj = {
          Applycode: localStorage.getItem('Applycode'),
          Applycity: localStorage.getItem('Applycity'),
          address_id: _this.address_id
        }
        _this.SETADDRESSCODE(obj)
      } else {
        _this.$emit('upShow', _this.post_code, _this.city_name)
        var obj = {
          Applycode: _this.post_code,
          Applycity: _this.city_name,
          address_id: _this.address_id
        }
        _this.SETADDRESSCODE(obj)
      }

    },

    toto1 () {
      this.$customJump({
        path: '/content/Privacy-policy',
      })
    },
    toto2 () {
      this.$customJump({
        path: '/content/termsAndConditions',
      })
    },
  },
}
